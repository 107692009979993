import {React} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../css/HomePage.css';

function Artists(props) {

  return (
    <main>
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/artists/uma-marcelo.jpeg"
              alt="Uma Maheshwar Reddy"
            />
            <Carousel.Caption>
              <h3>Uma Maheshwar Reddy</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/architectural-images/3.5.JPG"
              alt="Vinayak Shetty"
            />
            <Carousel.Caption>
              <h3>Vinayak Shetty</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/artists/Ray-New.jpeg"
              alt="Ray"
            />
            <Carousel.Caption>
              <h3>Ray</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header"> UMA MAHESHWAR REDDY </h1>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p> Over 8 years of experience in Brazilian Jiujitsu and still actively competing Uma will be heading the Jiujitsu classes at The Dojo <br/> <br/>
          Having trained at Marcelo Garcia Academy during which is considered to be one of the best and toughest rooms in the world, he is looking
          to bring the same intensity, vibes and atmosphere to The Dojo. His main intention is to be a facilitator instead of a teacher or coach which will enable
          him to continue growing along with his students/peers.
          <br/>
          Uma has also represented India in the 2023 Asian Games at Hangzhou China. He was part of the first Indian contingent of Jiujitsu. Though,
          he din't manage to medal, he hopes to build the enxt generation of talent to help bring more International medals for India.
          <br/>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p className="right-aligned"> Vinayak Shetty started training in Kyokushin Karate as a young teenager and has trained under some of the biggest names in the martial arts world over the years. He received his 1st Dan (degree) Black belt in 2000 and in 2006, after nearly ten years of training in India, he had an opportunity to visit and train in Japan. He returned to India and re-visited the basic techniques based on his learning, and tried to put into practice the high grade of karate that he experienced in Japan.
          <br/>
          <br/>
          In 2007, he had the chance to participate in the International Instructors Camp in Fukushima, where he graded successfully to second Dan. Later that year, he travelled to the United Kingdom to pursue a Master’s degree in of Business Administration from the University of Birmingham and graduated with distinction. During his time abroad, he also trained in Shotokan Karate at the University’s dojo and continued intensive training in Kyokushin Karate and successfully participated in the fifth European Full Contact Karate Championship in Lvov, Ukraine. The experience allowed him to take his fighting technique up a notch.
          <br/>
          <br/>
          Over the years, he has had considerable experience in Karate with a strong focus on karate fundamentals. He has completed the ‘uchi deshi‘ or live in student camp with Shihan Judd Reid three times in Thailand (2016,17,18). It is a unique camp run by Judd Reid who himself had lived in Japan as a personal student of the founder of Kyokushin Karate - Mas Oyama. The camps are an immersive experience where one can truly breathe karate, training intensely three times a day in the lap of nature starting with 6 am hill sprints to finishing the day with meditation as the sun sets on the beach. In 2017 Vinayak graded to third Dan passing a rigorous grading test put forth by Shihan Judd Reid. In September during an intensive 6 day seminar conducted by the legendary master Oiishi Daigo in Kolkata, he received his third Dan again having completed the gruelling grading again including an exhausting 30 men fight.          <br/>
          <br/>
          <br/>
          Vinayak has been appointed as the Indian branch chief of the Kyokushin kan organization and he wishes to continue his training and teaching which he is passionate about, and work towards developing a high standard of Kyokushin karate with emphasis on karate fundamentals. deep emphasis on physical conditioning in Kyokushin. The training aims specifically at developing inner strength and ultimate union of mind, body and spirit, which is the essence of Zen.
          <br/>
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header-right"> VINAYAK SHETTY </h1>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header"> RAY </h1>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p> Sensei Ray began his martial arts journey as a young child in 1993 at his school's karate club in Calcutta. He obtained his Black Belt in 2013 after nearly a decade of training. 
            <br/>
            <br/>
            Throughout the years, Sensei Ray expanded his expertise by training in Korean Traditional Karate, specifically Tang Soo Do (唐手道), earning his 2nd Dan in 2015. His work often took him across various cities in India, allowing him to train at different dojos whenever he had the opportunity. In 2020, he obtained his 3rd Dan from the World Tang Soo Do General Federation: Moo Duk Kwan in Seoul, South Korea.
            In his quest to reconnect with the roots of Traditional Karate that originated in Okinawa and later developed in mainland Japan by master Gichin Funakoshi, known as the father of Karate-Do and founder of the Japan Karate Association (JKA 日本 空手 協会); Sensei Ray joined JKAWF INDIA (Indian branch of the said JKA with its headquarters in Tokyo). 
            <br/>
            <br/>
            Currently Sensei Ray heads the JKA KARNATAKA, the state body of JKAWF INDIA in Karnataka headquartered in Bangalore. By 2021, he had achieved his 2nd Dan from the JKA, and obtained his 3rd Dan in 2024 from the Japan Karate Association, Japan examined by Katsutoshi Shiina Shihan the Technical Director of the JKA himself.
            Academically, Sensei Ray is a Graduate Architect from IIEST and later earned his Master of Design from IIT Bombay, finishing at the top of his class. Professionally, he is a Product Designer, a mentor, and a visiting faculty member for design at various institutes across India. Currently, he leads the Design team at a growth-stage startup in Bangalore.
            In addition to his diverse interests in design, Sensei Ray is passionate about Japanese culture and also actively dedicates time to care for community animals.         <br/>
          <br/>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p className="right-aligned"> Sensei Mrinmoy is an athlete and Karateka. He has trained in the art for over a decade under the able guidance of experienced Shotokan Karate instructors. 
          <br/>
          Sensei Mrinmoy has conducted classes for adults and children alike both in Kolkata and UAE. He has also conducted multiple fitness and self defence sessions for IT professionals in Bangalore. 
          <br/>
          Sensei Mrinmoy is also a certified Yoga Instructor and did a hat trick as State champion in Track & Field Event (West Bengal). 
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header-right"> MRINMOY </h1>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header"> PARTHA </h1>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p> Sensei Partha started his Karate journey in 2008. He has dedicated his life to teach JKA Karate and runs multiple dojos across Bangalore as part of Team JKA Karnataka (State body of JKAWF INDIA). 
            Sensei Partha is a Medal winner at the State Karate Chamionship (Shoto Cup 2010. Netaji State Championship 2011) and has also been a national player. 
          <br/>
          <br/>
          </p>
        </div>
      </div>
    </main>
  );
}

export default Artists
