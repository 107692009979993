import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Enquiry.css';
import '../css/Table.css';
import { useHistory, Link } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";
import moment from 'moment';

function Attendance(props) {
  const [attendance, setAttendance] = useState([]);
  const [disabled, setDisabled] = useState(0);
  const history = useHistory();
  const { user } = useAuth0();
  const [isEditing, setEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [role, setRole] = useState("")

  useEffect(() => {
    console.log(user)
    console.log("Front end working right");
    axios.get('/user?email=' + user.email)
    .then(res => {
      console.log(res.data)
      setRole(res.data.role)
    })
    .catch(err => console.log(err));

    axios.get('/attendance')
    .then(res => {
      setAttendance(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const exportAttendance = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.get('/order/export', {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `orders.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const attendanceHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Name </th>
          <th scope="col" data-sortable='true'> Class </th>
          <th scope="col" data-sortable='true'> Check In </th>
        </tr>
      </thead>
    </>
  )


  const attendanceDisplay = (
  <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 container b2corders">
    <table className='table row-number'>
      {attendanceHeader}
      <tbody >
      {attendance.map((checkin, i) => (
          <tr key={checkin._id}>
            <td className="section-hide"> { i+1 }</td>
            <td className="section-hide"> { checkin.name }</td>
            <td className="section-hide"> { checkin.class } </td>
            <td className="section-hide"> { moment(checkin.checkInDate).format('DD-MM-YYYY, h:mm a') }</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <main>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ATTENDANCE </h1>
            <input className="btn bulk-update-button btn-large" onClick={exportAttendance} type='submit' value={disabled ? 'Exporting Attendance...' : 'Export Attendance' }/>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {role === "admin" ? attendanceDisplay : "Page only Accessible By Admins"}
            </div>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default Attendance
