export const ARTSOPTIONS =
[
  {
    id: 0,
    name: "Brazilian Jiu Jitsu (BJJ)",
  },
  {
    id: 1,
    name: "Mixed Martial Arts (MMA)",
  },
  {
    id: 2,
    name: "JKA Karate",
  },
  {
    id: 3,
    name: "Kyokushin Karate",
  },
  {
    id: 4,
    name: "Muay Thai",
  },
]
