import {React } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import '../css/HomePage.css';


function Loading(props) {

  return (
    <div className="container loading">
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
    </div>
  );
}

export default Loading
// <p className="logout-text"> {isAuthenticated ? "Logout" : "Login"} </p>
