import {React, useState, useEffect } from 'react';
import {Card, CardDeck, ListGroup, ListGroupItem} from 'react-bootstrap'
import axios from 'axios'
import '../css/Enquiry.css';
import '../css/Receipt.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Modal} from 'react-bootstrap';
import {ARTSOPTIONS} from '../shared/artsOptions.js';
import {BATCHESOPTIONS} from '../shared/batchOptions.js';


function AdminUserProfile(props) {
  const [profile, setProfile] = useState({id: "",name: "",email: "",phone:"",bio:"",art:"", batch:"", endDate:"", startDate:""});
  const [address, setAddress] = useState({street1: "", city:"",state:"",zip:"",country:""})
  const [receipt, setReceipt] = useState({memberName:"", email:"", phone:"", membership: "", art:"", batch: "", amount:"",id:"", orderDate:""})
  const [membershipHistory, setMembershipHistory] = useState([])
  const [orders, setOrders] = useState([])
  const [order, createOrder] = useState({amount:"", tax:"", membership:"", art:"", membership:"", invoice:"", paymentStatus:"", date:""})
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  const [membershipEndDate, setMembershipEndDate] = useState();
  const [orderDate, setOrderDate] = useState();
  const [membershipStartDateOrder, setMembershipStartDateOrder] = useState();
  const [membershipFreezeStartDate, setMembershipFreezeStartDate] = useState();
  const [membershipFreezeEndDate, setMembershipFreezeEndDate] = useState();
  const [editMembershipStartDate, setEditMembershipStartDate] = useState();
  const [editMembershipEndDate, setEditMembershipEndDate] = useState();
  const [ value, onChange] = useState(new Date());
  const [edit, setEdit] = useState(false)
  const [row, setRow] = useState()
  const [artOptions] = useState(ARTSOPTIONS)
  const [batchOptions] = useState(BATCHESOPTIONS)


  useEffect(() => {
    // console.log(props.match.params.email)
    console.log(window.React1 === window.React2);
    console.log(props.match.params)
    axios.get('/user?id=' + props.match.params.email + "&location=admin" )
    .then(res => {
      console.log(res.data)
      setProfile({
        id: res.data._id || "",
        name: res.data.name || "",
        art: res.data.art || "",
        batch: res.data.batch || "",
        email: res.data.email || "",
        phone: res.data.phone || "",
        bio: res.data.bio || "",
        endDate: res.data.membership ? res.data.membership.endDate : "",
        startDate: res.data.createdAt || "",
      })
      if(res.data.membership) {
        setMembershipEndDate(new Date(res.data.membership.endDate))
        setMembershipStartDateOrder(new Date(res.data.membership.endDate))
      }
      if(res.data.membershipHistory) {
        setMembershipHistory((res.data.membershipHistory))
      }
      if(res.data.address) {
        setAddress({
          street1: res.data.address.street1 || "",
          city: res.data.address.city || "",
          state: res.data.address.state || "",
          zip: res.data.address.zip || "",
          country: res.data.address.country || "",
        })
      } else {
        setAddress({
          street1: "",
          city: "",
          state: "",
          zip:  "",
          country: "",
        })

      }
    })
    .catch(err => console.log(err));
    axios.get('/order?email=' + props.match.params.email + "&location=admin")
    .then(res => {
      setOrders(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const handleCreateOrder = e => {
    const { name, value } = e.target;
    createOrder(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSetProfile = e => {
    const { name, value } = e.target;
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSetAddress = e => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const viewReceipt = (i) => {
    setShow(true)
    console.log(i)
    console.log(orders[i].membership)
    setReceipt({
      memberName: profile.name || "",
      email: profile.email || "",
      phone: profile.phone || "",
      membership: orders[i].membership || "",
      amount: orders[i].amount || "",
      id: orders[i].orderId || "",
      orderDate: orders[i].orderDate || "",
    })
  };
  // const handleMembershipEndDate = e => {
  //   console.log(e.target.value)
  //   setMembershipEndDate(e.target.vale)
  // }
  
  const handleClose = () => {
    setShow(false)
  }
  
  const submitProfile = (e) => {
    e.preventDefault();
    axios.post('/user?email=' + profile.email, {
      name: profile.name,
      art: profile.art,
      batch: profile.batch,
      email: profile.email,
      phone: profile.phone,
      bio: profile.bio,
      street1: address.street1,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      adminPage: true,
      endDate: membershipEndDate
    })
    .then(res => {
      setProfile(prevState => ({
        ...prevState,
        name: res.data.name,
        art: res.data.art,
        batch: res.data.batch,
        email: res.data.email,
        phone: res.data.phone,
        bio: res.data.bio,
        endDate: res.data.membership ? res.data.membership.endDate : "",
      }));
      if(res.data.address) {
        setAddress(prevState => ({
          ...prevState,
          street1: res.data.address.street1,
          city: res.data.address.city,
          state: res.data.address.state,
          zip: res.data.address.zip,
          country: res.data.address.country,
        }));
      }
    })
    .catch(err => console.log(err));
  };

  const submitOrder = (e) => {
    e.preventDefault();
    console.log("entering front end")
    console.log(orderDate)
    axios.post('/order?email=' + profile.email, {
      amount: order.amount,
      tax: 18,
      art: profile.art,
      batch: profile.batch,
      membership: order.membership,
      paymentStatus: order.paymentStatus,
      orderId: "order_" + Date.now(),
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      orderDate: orderDate,
      startDate: membershipStartDateOrder,
      endDate: profile.endDate,
      invoice: "THEDOJO" + Date.now(),
      adminPage: true
    })
    .then(res => {
      createOrder(prevState => ({
        ...prevState,
        amount: "",
        tax: 18,
        art: profile.art,
        batch: profile.batch,
        membership: "",
        paymentStatus: "",
        orderDate: "",
        startDate: membershipStartDateOrder,
        }));
    })
    .catch(err => console.log(err));
  };

  const freezeMembershipFunc = (e) => {
    e.preventDefault();
    console.log("entering front end")
    console.log(orderDate)
    axios.post('/order/freeze-membership?email=' + profile.email, {
      membershipFreezeStartDate: membershipFreezeStartDate,
      membershipFreezeEndDate: membershipFreezeEndDate,
      adminPage: true
    })
    .then(res => {
      console.log(res)
      setMembershipHistory((res.data.membershipHistory))
      console.log("Membership Succesfully Frozen")

    })
    .catch(err => console.log(err));
  };

  const editMembershipHistoryRow = (i) => {
    console.log()
    // e.preventDefault();
    setEdit(!edit)
    setRow(i)
  };

  const submitMembershipHistoryChange = (e) => {
    e.preventDefault();
    console.log("entering front end")
    console.log(editMembershipStartDate)
    console.log(editMembershipEndDate)
    axios.post('/order/edit-membership-history?email=' + profile.email, {
      editMembershipStartDate: editMembershipStartDate,
      editMembershipEndDate: editMembershipEndDate,
      adminPage: true,
      row: row
    })
    .then(res => {
      console.log(res)
      console.log("Membership History Succesfully Edited")
      setMembershipHistory((res.data.membershipHistory))
    })
    .catch(err => console.log(err));
  };

  const orderHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> Date </th>
          <th scope="col" data-sortable='true'> Order ID </th>
          <th className="section-hide" scope="col" data-sortable='true'> Membership </th>
          <th className="col-width-200px" scope="col" data-sortable='true'> Amount </th>
        </tr>
      </thead>
    </>
  )

  const membershipHistoryHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> Membership Type </th>
          <th scope="col" data-sortable='true'> Start Date </th>
          <th scope="col" data-sortable='true'> End Date </th>
        </tr>
      </thead>
    </>
  )

  const ordersDisplay = (
    <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
      <table className='table row-number'>
        {orderHeader}
        <tbody >
        {orders.map((order, i) => (
          <tr key={order.orderId}>
            <td className="section-hide"> {moment(order.createdAt).format("DD-MMM-YY")}</td>
            <td className="section-hide"> {order.orderId}</td>
            <td className="section-hide"> {order.membership} </td>
            <td> {order.amount} </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )

  const orderCards = (
    <div className="full-width">
    <CardDeck className="profile-deck">
      {role === "" ?
        <Card className="profile-card col-lg-3 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Text>
              <select className='' type="text" name="membership" value={order.value} onChange={handleCreateOrder}>
                <option value=""> - </option>
                <option value="Ronin"> Day Pass </option>
                <option value="Shogun"> Monthly Pass</option>
                <option value="Paladin"> Quarterly Pass </option>
                <option value="Zen"> Annual Pass </option>
              </select>
              <p>Pass</p>
            </Card.Text>
            <Card.Text>
              <select className='' type="text" name="art" value={profile.art} onChange={handleCreateOrder}>
                <option value=""> - </option>
                {artOptions.map((arts) => (
                  <option value={arts.name}> {arts.name} </option>
                ))}
              </select>
              <p>Art</p>
            </Card.Text>
            <Card.Text>
              <select className='' type="text" name="batch" value={profile.batch} onChange={handleCreateOrder}>
                <option value=""> - </option>
                {batchOptions.map((batches) => (
                  <option value={batches.batch}> {batches.batch} </option>
                ))}
              </select>
              <p>Batch</p>
            </Card.Text>
            <Card.Text>
              <select className='' type="text" name="paymentStatus" value={order.paymentStatus} onChange={handleCreateOrder}>
                <option value=""> - </option>
                <option value="PAID"> PAID </option>
                <option value="UNPAID"> UNPAID </option>
              </select>
              <p>Payment Status</p>
              <input  className="form-control fixed-input" name="amount" value={order.amount} onChange={handleCreateOrder}/>
              <label htmlFor="amount">Amount</label>
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <DatePicker className="date-picker form-control fixed-input" selected={orderDate} dateFormat="dd/MM/yyyy" onChange={(date) => setOrderDate(date)} />
              <p>Payment Date</p>
              <DatePicker className="date-picker form-control fixed-input" selected={membershipStartDateOrder} dateFormat="dd/MM/yyyy" onChange={(date) => setMembershipStartDateOrder(date)} />
              <p>Membership Start Date</p>
            </ListGroupItem>
            <ListGroupItem>{order.orderId}</ListGroupItem>
          </ListGroup>
          <input className = "btn btn-large btn-primary" type='submit' value='CREATE ORDER' name="Submit" onClick={submitOrder} />

        </Card>
        :
        ""
      }
      </CardDeck>
      <div>
        <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders text-center">
          <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ORDER HISTORY</h1>

          <table className='table row-number'>
            <thead>
              <tr>
                <th scope="col" data-sortable='true'> Membership Type </th>
                <th scope="col" data-sortable='true'> Amount </th>
                <th scope="col" data-sortable='true'> Payment Status </th>
                <th scope="col" data-sortable='true'> Order Date </th>
                <th scope="col" data-sortable='true'> Order ID </th>
                <th scope="col" data-sortable='true'> Receipt </th>

              </tr>
            </thead>
            <tbody >
              {orders.map((order, i) => (
                <tr key={i}>
                  <td className="section-hide"> {order.membership}</td>
                  <td className="section-hide"> Rs.{order.amount}</td>
                  <td className="section-hide"> <strong> PAID </strong></td>
                  <td className="section-hide"> {order.orderDate ? moment(order.orderDate).format("DD-MMM-YY") : moment(order.createdAt).format("DD-MMM-YY")} </td>
                  <td className="section-hide"> {order.orderId} </td>
                  <td className="section-hide"> <input className = "btn btn-large btn-primary" type='submit' value='VIEW RECEIPT' name="Submit" onClick={() => viewReceipt(i)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const profileSection = (
    <div className="thumbnail center well well-sm text-center">
      <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> PROFILE</h1>
      <hr className="hr-large d-none d-md-block"/>
      <div className="rowcontainer">
        <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
          <div className="row">
            <div className="col-md-4">
              <div className="md-form">
                <input  className="form-control fixed-input" name="name" value={moment(profile.startDate).format("DD-MMM-YY")} disabled/>
                <label htmlFor="name">Membership Start Date</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="md-form">
                <input  className="form-control fixed-input" name="email" value={profile.email} disabled/>
                <label htmlFor="email">Your Email</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="md-form">
                <DatePicker className="date-picker form-control fixed-input" selected={membershipEndDate} dateFormat="dd/MM/yyyy" onChange={(date) => setMembershipEndDate(date)} />
                <label htmlFor="endDate">Membership End Date</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12">
          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="name" value={profile.name} onChange={handleSetProfile}/>
                <label htmlFor="name">Name</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="phone" value={profile.phone} onChange={handleSetProfile}/>
                <label htmlFor="phone">Phone Number</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <select className="form-control" name="art" value={profile.art} selected= {profile.art} onChange={handleSetProfile}>
                 <option value=""> - </option>
                  {artOptions.map((arts) => (
                    <option value={arts.name}> {arts.name} </option>
                  ))}
                </select>
                <label htmlFor="art">Your Art</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <select className="form-control" name="batch" selected= {profile.batch} value={profile.batch} onChange={handleSetProfile}>
                  <option value=""> - </option>
                  {batchOptions.map((batches) => (
                    <option value={batches.batch}> {batches.batch} </option>
                  ))}
                </select>
                <label htmlFor="art">Your Batch</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="md-form">
                <textarea  className="form-control" name="bio" value={profile.bio} onChange={handleSetProfile}/>
                <label htmlFor="message">Bio</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12">
          <div className="row">
            <div className="col-md-12">
              <div className="md-form">
                <input  className="form-control" name="street1" value={address.street1} onChange={handleSetAddress}/>
                <label htmlFor="name">Street</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="city" value={address.city} onChange={handleSetAddress}/>
                <label htmlFor="email">City</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="state" value={address.state} onChange={handleSetAddress}/>
                <label htmlFor="phone">State</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="zip" value={address.zip} onChange={handleSetAddress}/>
                <label htmlFor="phone">Zip Code</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input  className="form-control" name="country" value={address.country} onChange={handleSetAddress}/>
                <label htmlFor="phone">Country</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input className = "btn btn-large btn-primary" type='submit' value='UPDATE PROFILE' name="Submit" onClick={submitProfile} />
    </div>
  );

  const membershipHistorySection = (
    <div>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders text-center">
        <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> MEMBERSHIP HISTORY</h1>

        <table className='table row-number'>
          {membershipHistoryHeader}
          <tbody >
            {membershipHistory.map((membershipHis, i) => (
              <tr key={i}>
                <td className="section-hide"> {membershipHis.membershipType}</td>
                <td className="section-hide">
                  {edit && i===row ? <DatePicker className="date-picker form-control fixed-input" selected={editMembershipStartDate} dateFormat="dd/MM/yyyy" onChange={(date) => setEditMembershipStartDate(date)} /> : moment(membershipHis.startDate).format("DD-MMM-YY") }
                </td>
                <td className="section-hide">
                  {edit && i===row ? <DatePicker className="date-picker form-control fixed-input" selected={editMembershipEndDate} dateFormat="dd/MM/yyyy" onChange={(date) => setEditMembershipEndDate(date)} /> : moment(membershipHis.endDate).format("DD-MMM-YY") }
                </td>
                <td className="section-hide">
                  {
                    edit && i===row  ?
                      <>
                        <input className = "btn btn-large btn-primary" type='submit' value='Return' name="Submit" onClick={() => editMembershipHistoryRow(i)}/>
                        <input className = "btn btn-large btn-primary" type='submit' value='Submit' name="Submit" onClick={submitMembershipHistoryChange}/>
                      </>
                    : <input className = "btn btn-large btn-primary" type='submit' value='Edit' name="Submit" onClick={() => editMembershipHistoryRow(i)}/>
                  }
                </td>
              </tr>
            ))}
          </tbody>
          </table>
      </div>
    </div>
  );

  const freezeMembershipHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> Freeze Start Date </th>
          <th scope="col" data-sortable='true'> Freeze End Date </th>
          <th scope="col" data-sortable='true'> Approve </th>
        </tr>
      </thead>
    </>
  )

  const freezeMembership = (
    <div>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders text-center">
        <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> MEMBERSHIP FREEZE</h1>
        <table className='table row-number'>
          {freezeMembershipHeader}
          <tbody >
            <tr>
              <td className="section-hide">
                <DatePicker className="date-picker form-control fixed-input" selected={membershipFreezeStartDate} dateFormat="dd/MM/yyyy" onChange={(date) => setMembershipFreezeStartDate(date)} />
              </td>
              <td className="section-hide">
                <DatePicker className="date-picker form-control fixed-input" selected={membershipFreezeEndDate} dateFormat="dd/MM/yyyy" onChange={(date) => setMembershipFreezeEndDate(date)} />
              </td>
              <td className="section-hide">
                <input className = "btn btn-large btn-primary" type='submit' value='FREEZE MEMBERSHIP' name="Submit" onClick={freezeMembershipFunc} />
              </td>
            </tr>
          </tbody>
          </table>
      </div>
    </div>
  );

  const receiptSection = (
    <>
     <div>
        <p className="inv-no"><strong>Inv No: {receipt.id}</strong></p>  
        <p className="inv-no"><strong>Inv Date: {moment(receipt.orderDate).format("DD-MMM-YY")}</strong></p>       
      <div id="mid">
        <div className="contact-info">
          <h4>Contact Info</h4>
          <p> 
              Name   : {receipt.memberName}<br/>
              Email   : {receipt.email}<br/>
              Phone   : {receipt.phone}<br/>
          </p>
        </div>
      </div>
    
      <div id="bot">

        <div>
          <table className="table">
            <tr class="tabletitle">
              <td class="item"><h4>Item</h4></td>
              <td class="Hours"><h4>Qty</h4></td>
              <td class="Rate"><h4>Sub Total</h4></td>
            </tr>

            <tr class="service">
              <td class="tableitem"><p class="itemtext">{receipt.membership}</p></td>
              <td class="tableitem"><p class="itemtext">1</p></td>
              <td class="tableitem"><p class="itemtext">Rs. {receipt.amount}</p></td>
            </tr>

            <tr class="tabletitle">
              <td></td>
              <td class="Rate"><h4>Total Amount</h4></td>
              <td class="payment"><h4>Rs. {receipt.amount}</h4></td>
            </tr>

          </table>
        </div>
				</div>
      </div>
    </>
  )

  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header>
          <Modal.Title>
            <div>
              <img className = "thedojologo" src="https://cdn.razorpay.com/logos/HhAmdevLDzPKGa_medium.jpg"/> 
              <h2 className="receipt-header"> THE DOJO </h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {receiptSection}
        <hr className="hr-large"/>
        <p class="legal">The Dojo <br/> #403, 3rd Floor, 22nd Cross, 2nd Sector, HSR Layout, Bangalore, 560102
        <br/> <strong>Thank you for your business!</strong>  			</p>			

       </Modal.Body>
      </Modal>
      <div className="contact-form">
        <div className="fixing-newsletter">
          {profileSection}
          <hr className="hr-large"/>
          {membershipHistorySection}
          <hr className="hr-large"/>
          {freezeMembership}
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ORDERS </h1>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {orderCards}
            </div>
          </div>
          <hr className="hr-large"/>
        </div>
      </div>
    </main>
  );
}

export default AdminUserProfile
