import {React} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../css/HomePage.css';

function Arts(props) {

  return (
    <main>
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/arts/kyokushin-history.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/arts/bjj-history-3.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header"> KYOKUSHIN </h1>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p>
            The founder of Karate Kyokushinkai – Sosai Masutatsu Oyama – was born on the 27th of July, 1923, in a village in South Korea. At the age of 9, while staying at his sister’s farm in Mandjuriya, he started training in Chinese Kenpo and more particular “The Eighteen Hands” form. In 1938, Oyama left for Japan with the intention to enroll in an aviation school to become a jet pilot, but he had to give up on his dream. He continued to practice Judo and box, and his interest in martial arts led him to the dojo of Gichin Funakoshi, where he started practicing Okinawa Karate.
            <br/>
            <br/>
            Sosai Masutatsu Oyama – Founder of Kyokushin Karate Oyama advanced quickly due to his great dedication. He was only 20 years old when he achieved the rank of 4th Dan. In the same period of time, Oyama enrolled in the Japanese Imperial Army and started practicing Judo in order to master its hand grip techniques. In four years, he achieved the rank of 4th Dan in Judo and ended up its training.
            <br/>
            <br/>
            After the Japanese defeat in Second World War, Oyama, as any young Japanese, relapsed into personal anguish. He managed to find a way out of his despair while training with So Nei Chu, a Korean master in Goju-Ryu Karate. This great teacher famous for the strength of his body as well as for his deep spirituality had a great impact on the young Mas Oyama. Master So taught him the inhesion of BUDO and the spiritual foundations of Buddhism. After some years of traning, Master So advised Mas Oyama to make a vow to dedicate his life to the Warrior’s Way, and to seclude himself into the mountains, to train his mind and body.
            <br/>
            <br/>
            In October 1946, Oyama began exercising in isolation in the Minobu Mountain. Only two months later, he was forced to return to civilization due to the lack of supplies.
            <br/>
            <br/>
            In September 1947, he won unreservedly the All-Japan Open Karate Championship. However, this for him was not enough, and in 1948 Mas Oyama withdrew into the Kiosumi Mountain, in Chiba prefecture. He was followed by another trainee – Yashiro. The friend/sponsor Mr. Kayama supplied them with food every month. Through vigorous training, Mas Oyama learned to overcome the anxiety caused by the solitude, but Yashiro was unable to do this. After 6 months, he escaped, leaving Oyama alone. Eighteen months later, Mr. Kayma informed Masutatsu Oyama that, due to unexpected circumstances, he could no longer support the food provisions for his isolation in the mountains. As a result, Oyama’s original plan to remain cut off for three years was untimely interrupted.
            <br/>
            <br/>
            After returning from his isolation, Oyama won again the All-Japan Open Karate Championship. His success was followed by many victories in different martial championships and tournaments with dissimilar rules and regulations. Many times, he challenged to fight without rules masters from different martial arts, but they all ended up with a clear-cut win by Oyama. In this way, he practiced and perfected his Karate.
            <br/>
            <br/>
            Masutatsu Oyama opened his first dojo in 1953 in Meijiro, Tokyo. This was the time when the strength of Mas Oyama was in its highest point, and the trainings in the dojo were ruthless. Many of his apprentices were members of different martial arts, and Oyama compared their styles and forms with his Karate. He adopted the best techniques and concepts and incorporated them in his trainings. In this way, he set up the foundations of Kyokushin Karate. The formation of the world organization began in 1960, and it was officially open in July, 1965. It was named the International Karate Organization (IKO). It was at this time when Oyama introduced the name for his style in karate – Kyokushin (the ultimate truth) – and began spreading it all over the world, creating one of the biggest martial arts organizations on the planet. Because of his contribution to the development of karate, Oyama was awarded the honored title “Sosai”.
            <br/>
            <br/>
            Kyokushin Karate proved to be the hardest and most aggressive style in karate. Many of its disciples became champions in fights with different rulings. Kyokushin fighters were noted for their exceptional strength and high fighting spirit. This raised the authority of the style and won its recognition in the martial arts circles.
            <br/>
            <br/>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p className="right-aligned">
            The word “jiu jitsu” derives from the Japanese “Jū” meaning “gentle” and “Jutsu” meaning “art”; essentially, jiu jitsu is the “gentle art”. Brazilian Jiu Jitsu is a predominantly ground-based martial art, using the principals of leverage, angles, pressure and timing, as well as knowledge of the human anatomy, in order to achieve a non-violent submission of one’s opponent. Unlike other martial arts that focus on strikes and/or kicks, jiu jitsu focuses on close-contact “grappling” holds and techniques, and the application of chokes and joint-manipulations.
            <br/>
            <br/>
            The Brazilian style of jiu jitsu resulted from the import of Japanese jiu jitsu and judo to the South American continent in the early 1900s, and its transformation into a new martial art style by a core group of Brazilian pioneers.
            <br/>
            <br/>

            Before it arrived to the shores of Brazil, however, the origins of jiu jitsu are believed to go back thousands of years. There are several theories concerning its very first beginnings, but many agree that it can at least be traced back 4,000 years ago, to Buddhist monks in India, who needed to protect themselves during their travels without inflicting harm on their attackers. It then spread to feudal Japan, where it developed into an effective hand-to-hand combat during war, before transitioning to become more of an art form and a competitive activity. Some even argue for much older origins than India, dating back to the earliest forms of “grappling”, which are immortalized on the walls of ancient Greek and Egyptian ruins.
            <br/>
            <br/>

            Then in 1915, a world famous Japanese judoka, Mitsuyo Maeda, arrived to Brazil, where he soon began teaching and giving demonstrations in jiu jitsu and judo, which at the time were not seen as separate disciplines. Three of Maeda’s first students went on to become the founders of what is known as Brazilian Jiu Jitsu: brothers Carlos and Helio Gracie, and Luiz França. Each of these early pioneers contributed to the development of what is today known as Brazilian jiu jitsu, by maximizing the effectiveness of existing techniques, creating new ones, and giving rise to a distinct martial art.
            <br/>
            <br/>

            It wasn’t long before the Brazilian style of jiu jitsu started to spread to other countries and continents. Brazilian Jiu Jitsu arrived to the United States in the early 1970s, but grew relatively slowly until the 1990s, when the UFC brought mixed martial arts – and Brazilian Jiu Jitsu – to the mainstream public. In 2002, Carlos Gracie, Jr. founded the International Brazilian Jiu-Jitsu Federation, which began holding organized jiu jitsu competitions world-wide. In the last decade, jiu jitsu has boomed, and today, many of the world’s largest and most prestigious tournaments are held in the United States.
            <br/>
            <br/>

            All Brazilian jiu jitsu practitioners can trace their “lineage” back to one of the founding members. Professor JT’s lineage can be traced back to Carlos and Helio Gracie.            <br/>
            <br/>
            More than just a workout, jiu jitsu is often referred to as “human chess” in that it exercises both the body and the mind.
            <br/>
            <br/>

            The physical benefits of jiu jitsu are immense and well-documented. Because jiu jitsu presents a combination of dynamic and explosive movements with pressure-based isometric pushing, pulling, and holding, it helps to develop increased strength and cardio, as well as promotes weight loss and improved muscle tone. Because you’re constantly responding to another person’s movements, jiu jitsu also heightens your body awareness and improves your balance and reactive ability.
            <br/>
            <br/>

            Jiu jitsu is also an incredible mental workout. For one, it provides the challenge of learning a new activity, with limitless variations of movements, techniques and submissions. It also develops your reasoning skills, in that you are constantly planning your next step, both when you’re in an advantageous position or a defensive position. As an activity practiced with another person, it increases your ability think quickly and “on your feet” in response to your partner’s movements, as well as to remain calm and controlled when you find yourself in a defensive situation.
            <br/>
            <br/>

            For many, it also provides an outlet for the stresses of daily life. When you step on the mat, everything else is put to the side for a moment, while both your mind and body are engaged in learning jiu jitsu.
            <br/>
            <br/>

            Most importantly, unlike many other sports and physical activities, jiu jitsu is unique in that it can be done by anyone. Jiu Jitsu was designed and developed so that a smaller, weaker person can defend his or herself against, or even defeat, a larger and stronger opponent. So regardless of age, size, gender or physical limitation, any person can utilize the techniques and principals of jiu jitsu, and reap the benefits of its practice.            <br/>
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header-right"> BRAZILIAN JIU JITSU </h1>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header"> STRIKING (MUAY THAI KICKBOXING) </h1>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p>
            The term striking in martial arts generally refers to stand up fighting, which means everything that’s not grappling (even though strikes can be performed on the ground as well). Therefore, striking better encompasses what they do on their feet than terms like karate or Muay Thai.
            Striking is often used in MMA because MMA fighters try to combine some of the best striking techniques from different martial arts.
            <br/>
            <br/>
            Kickboxing is a modern martial art that includes several sports/disciplines, namely: Semi contact, Light contact, Kick light, Full contact, Low kick, K-1, Thai kickboxing similar rules to Thai boxing), Musical Forms and Aero-kickboxing.
            <br/>
            <br/>
            Except in disciplines where knock-outs are allowed, fights for the world and European professional titles are held in semi and light contact.
            <br/>
            <br/>
            Kickboxing was created on the basis of boxing, karate, taekwondo, kung fu and other more or less famous oriental and western martial arts.
            <br/>
            <br/>
            The main goal of every fighter is to overcome his opponent with better technique, speed, and strength while using maximum security, tolerance, honesty, and honor of each competitor.
            <br/>
            <br/>
            In a word, Kickboxing is a Western sport – a unique answer to a large number of Eastern martial arts.
            <br/>
            <br/>
            In the broad sense, kickboxing is a group of stand-up combat sports that include kicking and punching.
            <br/>
            <br/>
            Even thou kickboxing is not so effective hand striking technique as boxing, it does have boxing in its name, and that is not without reason, it relies heavily on boxing skills.
            <br/>
            <br/>
            Kickboxers could stand on their own in a boxing match, which cannot be said for the best and most effective striking martial art in the world since they rely more on kicks and leg techniques than kickboxers. Kickboxing also uses more punch combos, includes more headwork (just like boxing) and its footwork is much more similar to regular boxing.
            <br/>
            <br/>
            Typical kickboxing kicks rely heavily on bending and withdrawing the foot after the kick and rely more on the use of kicks. It demands faster, more aggressive footwork that is aimed at attacking the opponent.
            <br/>
            <br/>
            Kickboxers tend to start very strongly, very aggressively from the first sound of the bell (or street fight) and the pace slows down as the rounds (fights) pass because the energy level of the fighters decreases.
            <br/>
            <br/>
            Muay Thai or Thai Boxing is the national sport and cultural martial art of Thailand. It was developed several hundred years ago as a form of close-combat that utilizes the entire body as a weapon.
            <br/>
            <br/>
            According to the rules, punches, and kicks are allowed with the use of knees, elbows, and holding, pushing and throwing (without the use of locks), which makes it different from other similar sports (K1, kickboxing).
            <br/>
            <br/>
            Such rules require fighters a great degree of endurance and polyvalence in the way of fighting. The level of workload in fights is extremely high, so the top competitors of this sport have a reputation as fighting “supermans”.
            <br/>
            <br/>
            Unlike Kickboxing, classical Muay Thai, as practiced in Thailand, usually has five rounds and it is normal for fighters to use the first two rounds to “examine” each other before the real fighting begins in round three. This is, of course, mostly limited to Asian Muay Thai; Western practitioners usually start fighting from the beginning of the fight.
            <br/>
            <br/>
            Muay Thai kicks usually come from the ground in an explosive manner and the fighter’s leg doesn’t bend while kicking. Muay Thai is an art that requires patience. Thus, a fighter has to adapt his footwork to patiently follow the opponent and his movements in order to find the right moment to strike.
            <br/>
            <br/>
            Muay Thai fighters rely more on kicks and leg techniques than kickboxers, while not so much on hand techniques.
            <br/>
            <br/>
            Even thou kickboxing and Muay Thai are pretty similar martial arts, to well-known fighters they are completely different, and if you would like to find out more about Muay Thai and Kickboxing differences, check out our article.
            <br/>
            <br/>
            Muay Thai is the best striking martial art in the world, but it has a big rival in ‘similar’ kickboxing.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <p className="right-aligned">
          JKA karate has remained essentially unchanged throughout its history. Each student learns each technique and tradition in its original and most powerful form. As a result, the JKA produces some of the strongest and most skillful karate-ka in the world. There are a number of practical factors that make JKA karate different from other karate traditions.
            <br/>
            <br/>
            JKA karate is a unique refinement of the Shotokan style, one of the oldest and most traditional types of Okinawan karate. Since it descends directly from such “original” sources, it has developed from strong roots underpinned by a long history and a great tradition.
            <br/>
            <br/>

            Every technique and movement in JKA karate is based on solid, scientific, physical movement theory. That’s why we put such heavy focus—far more than any other organization—on kihon, the basic foundational techniques, and their proper form, angle and balance. The result speaks for itself: JKA karate is powerful, solid, stable, and smooth flowing; this has proven itself time and again in tournaments. Despite the excellence of JKA karate, however, it is constantly being improved.

            <br/>
            <br/>

            Karate is not a sport one plays for points. In JKA kumite, there are no weight classifications and no arbitrary point system. At the JKA there is only ippon (“one full point,” which means you have downed your opponent and won), or waza-ari (“effective technique,” which counts for roughly 80% of an ippon). The basis of JKA karate is the ability to take down your opponent, regardless of size or weight, with one blow. There is no room for incremental points in such a tradition. The difference is obvious: we teach our students how to gain mastery of themselves and overwhelm the opponent.
            <br/>
            <br/>

            Unlike many other karate organizations, the JKA was not created as an organization whose major purpose is holding matches and tournaments. Though it does sponsor tournaments, its major focus is the practice of karate. Its purpose is the teaching of karate as a way of life.
            <br/>
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h1 className="index-header-right"> SHOTOKAN KARATE </h1>
        </div>
      </div>
    </main>
  );
}

export default Arts
