import {React, useState, useEffect } from 'react';
import {Button} from 'react-bootstrap'
import axios from 'axios'
import '../css/Dashboard.css';
import { useHistory, Link } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";
import { CSVLink } from "react-csv";
import {Modal} from 'react-bootstrap';
import moment from 'moment';

function Dashboard(props) {
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(0);
  const { user, loading } = useAuth0();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [newCustomer, setNewCustomer] = useState({name: "",phone:"",email:"", art:""});
  const [isEditing, setEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [role, setRole] = useState("")
  const [show, setShow] = useState(false);

  useEffect(() => {
    axios.get('/user?email=' + user.email)
    .then(res => {
      setRole(res.data.role)
    })
    .catch(err => console.log(err));

    axios.get('/user/all-users/')
    .then(res => {
      setUsers(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const handleCustomer = e => {
    const { name, value } = e.target;
    setNewCustomer(prevState => ({
        ...prevState,
        [name]: value
    }));
  };


  const customerUpdate = (event) => {
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    axios.post('/user?tenantId=thedojo', {
      name: newCustomer.name,
      phone: newCustomer.phone,
      email: newCustomer.email,
      art: newCustomer.art,
      startDate: Date.now(),
      adminPage: 1
     })
    .then(res => {
      setShow(false)
      console.log("Successfully added to database")
      setNewCustomer(prevState => ({
          ...prevState,
          name: "",
          phone: "",
          email: "",
          art: ""
      }));
      console.log(res.data)
      users.push(res.data);
    })
    .catch(err => console.log(err));
  };

  const editRowStatusFunc = (e) => {
    setEditing(true);
    setEditRow(e.target.name);
    // console.log(leadEdit)
  }

  const addMember = (e) => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const updatePaymentStatus = (e) => {
    axios.put('/user?email=' + editRow, {
      paymentStatus: paymentStatus,
    })
    .then(res => {
      console.log(res.data)
      var foundIndex = users.findIndex(x => x.email == res.data.email);
      users[foundIndex] = res.data;
    })
    .catch(err => console.log(err));
  }

  const renewMembership = (e) => {
    console.log(e.target.name)
    axios.post('/email/membership-renewal', {
      email: e.target.name
    })
    .then(res => {
      console.log("Sucesfully sent reminder email")
    })
    .catch(err => console.log(err));
  }


  return (
    <main>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> DASHBOARD </h1>
            <div className="bulk-update section-hide">
            <iframe className = "iframe" src="https://charts.mongodb.com/charts-thedojoblr-cmklpbj/embed/charts?id=66b34f2c-9750-4de5-8bea-cfdc3002ce34&maxDataAge=3600&theme=dark&autoRefresh=true"></iframe>
            </div>
            <div className="bulk-update section-hide">
            <iframe className = "iframe" src="https://charts.mongodb.com/charts-thedojoblr-cmklpbj/embed/charts?id=66b34fbd-78bb-4eb5-8412-1adf71d7a5b3&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
            </div>

            <hr className="hr-large d-none d-md-block"/>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default Dashboard
