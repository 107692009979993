import {React, useState, useEffect } from 'react';
import {Tooltip,} from 'react-bootstrap'
import {Modal} from 'react-bootstrap';
import axios from 'axios'
import '../css/Enquiry.css';
import { useAuth0 } from "../react-auth0-spa";
import moment from 'moment';
import {ARTSOPTIONS} from '../shared/artsOptions.js';
import {BATCHESOPTIONS} from '../shared/batchOptions.js';

function NewMember(props) {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const { user, loading } = useAuth0();
  const [newCustomer, setNewCustomer] = useState({name: "",phone:"",email:"", art:""});
  const [role, setRole] = useState("");
  const [artOptions] = useState(ARTSOPTIONS)
  const [batchOptions] = useState(BATCHESOPTIONS)


  const editPaymentStatus = props => (
    <Tooltip {...props}>Edit Fulfillment Statu</Tooltip>
  );

  useEffect(() => {
    axios.get('/user?email=' + user.email)
    .then(res => {
      setRole(res.data.role)
    })
    .catch(err => console.log(err));
  }, []);

  const handleCustomer = e => {
    const { name, value } = e.target;
    setNewCustomer(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const customerUpdate = (event) => {
    setShow(true)
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    console.log(newCustomer)
    axios.post('/user?tenantId=thedojo', {
      name: newCustomer.name,
      phone: newCustomer.phone,
      email: newCustomer.email,
      art: newCustomer.art,
      batch: newCustomer.batch,
      startDate: Date.now(),
      adminPage: 1
     })
    .then(res => {
      console.log("Successfully added to database")
      setNewCustomer(prevState => ({
          ...prevState,
          name: "",
          phone: "",
          email: "",
          art: ""
      }));
      console.log(res.data)
    })
    .catch(err => console.log(err));
  };

  const handleClose = () => {
    window.location.reload(false);
    setShow(false)
  }

  const newMember = (
    <>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="row">
          <div className="col-md-6">
            <div className="md-form">
              <input  className="form-control" name="name" value={newCustomer.name || ""} onChange={handleCustomer} /> 
              <label htmlFor="name">Name</label>
              <div className="invalid-feedback">Enter Your Name!</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form">
              <input  className="form-control" name="phone" value={newCustomer.phone || ""}  onChange={handleCustomer}/>
              <label htmlFor="phone">Phone Number</label>
              <div className="invalid-feedback">Please Enter a Valid Phone Number</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="md-form">
              <input  className="form-control" name="email" value={newCustomer.email || ""}  onChange={handleCustomer}/>
              <label htmlFor="email">Your Email</label>
              <div className="invalid-feedback">Please Provide a Valid Email ID!</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form">
              <select className="form-control" name="batch" value={newCustomer.batch} onChange={handleCustomer}>
                <option value="">--</option>
                {batchOptions.map((batches) => (
                  <option value={batches.batch}> {batches.batch} </option>
                ))}
              </select>
              <label htmlFor="Batch">Batch</label>
              <div className="invalid-feedback">Please choose your Batch!</div>
              </div>
            </div>
          <div className="col-md-6">
            <div className="md-form">
              <select className="form-control" name="art" value={newCustomer.art} onChange={handleCustomer}>
                <option value="">--</option>
                {artOptions.map((arts) => (
                  <option value={arts.name}> {arts.name} </option>
                ))}
              </select>
              <label htmlFor="Art">Art</label>
              <div className="invalid-feedback">Please choose your art!</div>
              </div>
            </div>
        </div>

          <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={customerUpdate}  disabled={disabled} />
        </div>
    </>
  )

  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Member Succesfully Created </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Member succesfully created. You can verify the creation at <a href="https://www.thedojoblr.com/users">here.</a> </p>
         <hr className="hr-large"/>
       </Modal.Body>
      </Modal>

      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> NEW MEMBER PAGE </h1>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {role === "admin" ? newMember : "Page only Accessible By Admins"}
            </div>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default NewMember

