import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Enquiry.css';
import { useAuth0 } from "../react-auth0-spa";
import { CSVLink } from "react-csv";
import moment from 'moment';

function Enquiries(props) {
  const [enquiries, setEnquiries] = useState([]);
  const { user, loading } = useAuth0();
  const [role, setRole] = useState("")

  useEffect(() => {
    axios.get('/user?email=' + user.email)
    .then(res => {
      setRole(res.data.role)
    })
    .catch(err => console.log(err));

    axios.get('/enquiry/all-enquiries/')
    .then(res => {
      setEnquiries(res.data)
    })
    .catch(err => console.log(err));
  }, []);



  const userHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Name </th>
          <th scope="col" data-sortable='true'> Email </th>
          <th scope="col" data-sortable='true'> Phone </th>
          <th scope="col" data-sortable='true'> Art / Sub </th>
          <th scope="col" data-sortable='true'> Date </th>
          <th scope="col" data-sortable='true'> Message </th>
        </tr>
      </thead>
    </>
  )

  const usersDisplay = (
  <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 container b2corders">
    <table className='table row-number'>
      {userHeader}
      <tbody >
      {enquiries.map((enquiry, i) => (
          <tr key={user._id}>
            <td className="section-hide"> { i+1 }</td>
            <td className="section-hide"> { enquiry.name } </td>
            <td className="section-hide"> { enquiry.email } </td>
            <td className="section-hide"> { enquiry.phone }</td>
            <td className="section-hide"> { enquiry.art || enquiry.sub }</td>
            <td className="section-hide"> { moment(enquiry.createdAt).format('DD-MM-YYYY') }</td>
            <td className="section-hide"> { enquiry.msg } </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )

  return (
    <main>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Enquiries </h1>
            <div className="bulk-update section-hide">
              <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={enquiries.map( enquiry => ({
                Name: enquiry.name,
                Phone: enquiry.phone,
                Email: enquiry.email,
                StartDate: moment(enquiry.createdAt).format('DD-MM-YYYY'),
                Message: enquiry.message,
              }))} > Export Enquires </CSVLink>
            </div>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {role === "admin" ? usersDisplay : "Page only Accessible By Admins"}
            </div>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default Enquiries
