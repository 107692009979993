import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Attendance.css';
import { useHistory } from 'react-router-dom';
import {Modal, Input} from 'react-bootstrap';
import moment from 'moment';

function Enquiry(props) {
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [checkedInMembers, setCheckedInMembers] = useState([]);
  const [attendance, setAttendance] = useState({member: "",checkInDate: "",class:""});
  const [users, setUsers] = useState([]);
  const [checkedInMember, setCheckedInMember] = useState({member:"", membershipEndDate: "", status:""})
  const [disabled, setDisabled] = useState(0);
  const history = useHistory();

  useEffect(() => {
    axios.get('/user/all-users')
    .then(res => {
      setUsers(res.data)
    })
    .catch(err => console.log(err));

    axios.get('/attendance?page=checkin')
    .then(res => {
      setCheckedInMembers(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    var member = users.filter(user => user.name === searchValue)
    console.log(member);
    if(member.length != 0){
      console.log("member" + member[0].name)
      setAttendance({
        member: member[0].name
      })
      if(moment(new Date()).isAfter(moment(member[0].membership.endDate))) {
        var currentStatus = "Hey " + member[0].name + ", your membership with us has expired on:" + moment(member[0].membership.endDate).format('DD-MM-YYYY') + ". Please renew your membership for continued access to The Dojo. You can renew your membership by visiting https://www.thedojoblr.com/membership."
      } else {
        var currentStatus = "Hey " + member[0].name + ", thank you for being part of the Dojo. Your membership is due for renewal on: " + moment(member[0].membership.endDate).format('DD-MM-YYYY')
      }
      setCheckedInMember({
        member: member[0].name,
        membershipEndDate: member[0].membership.endDate,
        status: currentStatus
      })
    }
  }

  const handleSetAttendance = e => {
    const { name, value } = e.target;
    setAttendance(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submitAttendance = (e) => {
    setShow(true)
    e.preventDefault();
    attendance.checkInDate = new Date();
    axios.post('/attendance', {
      name: attendance.member,
      class: attendance.class,
      checkInDate: attendance.checkInDate,
      tenantId: "thedojo"
    })
    .then(res => {
      console.log("Entered Res")
      setAttendance(prevState => ({
          ...prevState,
          member: "",
          class: "",
          checkInDate: "",
      }));
    })
    .catch(err => console.log(err));
  };

  const handleClose = () => {
    window.location.reload(false);
    setShow(false)
  }

  const checkedInMembersHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Name </th>
          <th scope="col" data-sortable='true'> Class </th>
          <th scope="col" data-sortable='true'> Check In </th>
        </tr>
      </thead>
    </>
  )

  const checkedInMembersDisplay = (
    <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
      <table className='table row-number'>
        {checkedInMembersHeader}
        <tbody >
        {checkedInMembers.map((checkin, i) => (
            <tr key={checkin._id}>
              <td className="section-hide"> { i+1 }</td>
              <td className="section-hide"> { checkin.name }</td>
              <td className="section-hide"> { checkin.class } </td>
              <td className="section-hide"> { moment(checkin.checkInDate).format('DD-MM-YYYY, h:mm a') }</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )


  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Succesfully Checked In </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> {checkedInMember.status} </p>
          <p> You're all set for your class. Have a great session! </p>
         <hr className="hr-large"/>
       </Modal.Body>
      </Modal>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> CHECK IN </h1>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
                <div className="md-form">
                  <input  className="form-control check-in" name="email" value={searchInput}  onChange={(e) => searchItems(e.target.value)} list="members" autocomplete="off"/>
                  <datalist id="members" value={attendance.member || ""} name="member" onChange={handleSetAttendance}>
                    <option value=""></option>
                    {users.map((user, i) => (
                      user.name ?
                      <option key={i} value={user.name}>{user.name}</option>
                      :""
                    )) }
                  </datalist>
                </div>
                <label className="space-bottom" htmlFor="member">Your Name</label>
                <div className="md-form">
                <select value={attendance.class|| ""} name="class" onChange={handleSetAttendance}>
                  <option value=""></option>
                  <option value="Kyokushin Karate">Kyokusin Karate</option>
                  <option value="Mixed Martial Arts">Mixed Martial Arts</option>
                  <option value="Brazilian Jiu Jitsu">Brazilian Jiu Jitsu</option>
                  <option value="Muay Thai">Muay Thai</option>
                  <option value="Kids Mixed Martial Arts">Kids Mixed Martial Arts</option>
                  <option value="Kids Shotokan Karate">Kids Shotokan Karate</option>
                  <option value="Kids Shotokan Karate - Kai Early Years">Kids Shotokan Karate - Kai Early Years</option>
                </select>
              </div>
              <label className="space-bottom" htmlFor="lass">Class</label>
              <div>
                <input className = "btn btn-large btn-primary" type='submit' value='SUBMIT' name="Submit" onClick={submitAttendance} disabled={attendance.member === "" || attendance.class === ""}/>
              </div>
             </div>
           </div>
           <div className="rowcontainer">
            {checkedInMembersDisplay}
           </div>
         </div>
       </div>
       <hr className="hr-large d-none d-md-block"/>
   </div>
</main>
  );
}

export default Enquiry
