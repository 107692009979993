import {React, useState } from 'react';
import axios from 'axios'
import '../css/Enquiry.css';
import { useHistory } from 'react-router-dom';
import {ARTSOPTIONS} from '../shared/artsOptions.js';

function Enquiry(props) {
  const [enquiry, setEnquiry] = useState({name: "",email: "",phone:"",art:"",msg:""});
  const history = useHistory();
  const [artOptions] = useState(ARTSOPTIONS)

  const handleSetEnquiry = e => {
    const { name, value } = e.target;
    setEnquiry(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submitEnquiry = (e) => {
    e.preventDefault();
    axios.post('/enquiry', {
      name: enquiry.name,
      email: enquiry.email,
      phone: enquiry.phone,
      art: enquiry.art,
      msg: enquiry.msg,
      tenantId: "thedojo"
    })
    .then(res => {
      setEnquiry(prevState => ({
          ...prevState,
          name: "",
          email: "",
          phone: "",
          art: "",
          msg: "",
      }));
      history.push(`/thank-you-for-contacting-us`);
    })
    .catch(err => console.log(err));
  };

  return (
    <main>
      <div className="contact-form">
      <div className="fixing-newsletter">
        <div className="thumbnail center well well-sm text-center">
          <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> CONTACT US</h1>
          <hr className="hr-large d-none d-md-block"/>
          <div className="rowcontainer">
           <div className="col-md-12 col-xl-8 col-sm-12 col-xs-12">
             <div className="row">
               <div className="col-md-4">
                <div className="md-form">
                  <input  className="form-control" name="name" value={enquiry.name} onChange={handleSetEnquiry}/>
                    <label htmlFor="name">Your Name</label>
                    <div className="invalid-feedback">Enter Your Name!</div>
                 </div>
               </div>
               <div className="col-md-4">
                <div className="md-form">
                  <input  className="form-control" name="email" value={enquiry.email} onChange={handleSetEnquiry}/>
                  <label htmlFor="email">Your Email</label>
                  <div className="invalid-feedback">Please Provide a Valid Email ID!</div>
                </div>
               </div>
               <div className="col-md-4">
                <div className="md-form">
                  <input  className="form-control" name="phone" value={enquiry.phone} onChange={handleSetEnquiry}/>
                  <label htmlFor="phone">Your Phone Number</label>
                  <div className="invalid-feedback">Please Enter a Valid Phone Number</div>
                </div>
               </div>
             </div>
             <div className="row">
              <div className="col-md-6 center">
                <div className="md-form">
                  <select  className="form-control" name="art" value={enquiry.art} onChange={handleSetEnquiry}>
                    <option value="--"> -- </option>
                    <option value="Brazilian Jiu Jitsu (Adults)"> Brazilian Jiu Jitsu (Adults)</option>
                    <option value="Brazlian Jiu Jitsu (Begginers/Teens)"> Brazlian Jiu Jitsu (Begginers/Teens) </option>
                    <option value="Kyokushin Karate (Adults)"> Kyokushin Karate (Adults)  </option>
                    <option value="Muay Thai (Adults)"> Muay Thai (Adults) </option>
                    <option value="Mixed Martial Arts (Adults)"> Mixed Martial Arts (Adults)  </option>
                    <option value="JKA Shotokan Karate (Adults)"> JKA Shotokan Karate (Adults) </option>
                    <option value="JKA Shotokan Karate (Teens/Kids)"> JKA Shotokan Karate (Teens/Kids) </option>
                    <option value="Other"> Other </option>
                  </select>
                  <label htmlFor="art">Art</label>
                  <div className="invalid-feedback">Please choose your art!</div>
                 </div>
               </div>
             </div>
             <div className="row">
              <div className="col-md-12">
                <div className="md-form">
                  <textarea  className="form-control" name="msg" value={enquiry.msg} onChange={handleSetEnquiry}/>
                  <label htmlFor="message">Your Message</label>
                  <div className="invalid-feedback">Please enter your message!</div>
                </div>
              </div>
           </div>
            <input className = "btn btn-large btn-primary" type='submit' value='SUBMIT' name="Submit" onClick={submitEnquiry} />
           </div>
           <div className="col-md-12 col-xl-4 col-sm-12 col-xs-12 contactinfo">
            <p>
              <strong>Customer Service Enquires:</strong>
              <br/>
              Phone No: +919769091471 <br/>
              Phone No: +918142037710 (Aviailable Only On WhatsApp)
              <br/>
              Email ID: hello@thedojoblr.com
              <br/>
              <strong>Mailing Address:</strong>
              <br/>
              #403, 3rd Floor, 22nd Cross, 2nd Sector
              <br/>
              HSR Layout, Bangalore, India, 560102
              <br/>
              <strong>Walk Ins / Trial / Enquires :</strong>
              <br/>
              Please note that we don't entertain walk ins. Please use the enquiry form to send us a request or email us at hello@thedojoblr.com. We will book a trial session as per the availibility and convenience of our instructors and send an email confirming the same. Please visit our <a href='/schedule'>schedule</a> page to understand our timings and plan your visit accordingly.
            </p>
           </div>
         </div>
       </div>
     </div>
     <hr className="hr-large d-none d-md-block"/>
   </div>
</main>
  );
}

export default Enquiry
