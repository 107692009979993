export const MENUOPTIONS =
[
  {
    id: 0,
    name: "The Dojo Experience",
    link: "/the-dojo-experience"
  },
  {
    id: 1,
    name: "The Arts",
    link: "/the-arts"
  },
  {
    id: 2,
    name: "The Artists",
    link: "/the-artists"
  },
  // {
  //   id: 3,
  //   name: "Privates",
  //   link: "/privates"
  // },
  {
    id: 4,
    name: "Membership",
    link: "/membership"

  },
  {
    id: 5,
    name: "Schedule",
    link: "/schedule"
  },
  {
    id: 6,
    name: "Enquiry",
    link: "/enquiry"
  },
  // {
  //   id: 7,
  //   name: "Shop",
  //   link: "https://fluviumwear.com/shop"
  // },
]
