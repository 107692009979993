import {React, useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import {Card, CardDeck, ListGroup, ListGroupItem, Button, Modal, Image} from 'react-bootstrap';
import '../css/Membership.css';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";

function Membership () {
  const [show, setShow] = useState(false);
  const [membershipAmount, setMembershipAmount] = useState(0)
  const [membershipType, setMembershipType] = useState("")
  const [selectedPlan, setPlan] = useState("")
  const [profile, setProfile] = useState({name: "",email: "",phone:""});
  const history = useHistory();
  const [amount, setAmount] = useState(0)
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    // console.log(user)
    console.log(isAuthenticated)
    console.log(loginWithRedirect)
    if(isAuthenticated) {
      axios.get('/user?email=' + user.email)
      .then(res => {
        console.log(res)
        setProfile({
          name: res.data.name || "",
          email: res.data.email || "",
          phone: res.data.phone || "",
        })
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
      })
      .catch(err => console.log(err));
    }
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  const toggleMembership = (e) => {
    if(e.target.value === "700") {
      setMembershipType("Ronin Pass")
    } else if (e.target.value === "2800") {
      setMembershipType("Little Warrior Pass")
    }else if (e.target.value === "4800") {
      setMembershipType("Samurai Pass")
    } else if (e.target.value === "3800") {
      setMembershipType("Shogun Pass")
    } else if (e.target.value === "5600") {
      setMembershipType("Gladiator Pass")
    } else if (e.target.value === "14000") {
      setMembershipType("Paladin Pass")
    } else if (e.target.value === "50000") {
      setMembershipType("Zen Pass")
    } else if (e.target.value === "1") {
      setMembershipType("Test Pass")
    }
    console.log(membershipType)

    setMembershipAmount(e.target.value)
    setShow(true);
  }

  const togglePlan = (e) => {
    if(e.target.value === "bjj") {
      setPlan("bjj")
    } else if (e.target.value === "kyokushin") {
      setPlan("kyokushin")
    } else if (e.target.value === "trial") {
      setPlan("trial")
    } else if (e.target.value === "jka") {
      setPlan("jka")
    } else if (e.target.value === "mma") {
      setPlan("mma")
    } else if (e.target.value === "muaythai") {
      setPlan("muaythai")
    } else if (e.target.value === "kidsjka") {
      setPlan("kidsjka")
    } else if (e.target.value === "allaccess") {
      setPlan("allaccess")
    } 
    // console.log(plan)
  }

  const submitOrder = (event) => {
    setShow(false);
    event.preventDefault();
    axios.post('/razorpay', {
      amount: membershipAmount,
      currency: "INR",
      name: profile.name,
      email: profile.email,
    })
    .then(res => {
      console.log(res.data)
      const options = {
        key: "rzp_live_ws6On1bGWL78PH", // Enter the Key ID generated from the Dashboard
        amount: res.data.amount.toString(),
        currency: "INR",
        name: 'THE DOJO',
        description: membershipType,
        image: "https://s3.ap-south-1.amazonaws.com/thedojoblr.com/thedojo-logo-nobg.png",
        order_id: res.data.id,
        handler: async function (response) {
          const data = {
            orderId: res.data.id,
            membership: membershipType,
            phone: profile.phone,
            email: profile.email,
            name: profile.name,
            amount: membershipAmount,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          axios.post("/razorpay/confirmation?email=" + user.email, data)
          .then((result) => {
            history.push('/order-confirmation')
          })
          .catch(err => console.log(err.response));
        },
        prefill: {
            name: profile.name,
            email: profile.email,
            contact: profile.phone,
        },
        notes: {
            address: "The Dojo BLR",
        },
        theme: {
            color: "#ae8f63",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    })
    .catch(err => console.log(err.response));
  }

  const handleSetProfile = e => {
    const { name, value } = e.target;
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submitProfile = (e) => {
    e.preventDefault();
    axios.post('/user?email=' + user.email, {
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
    })
    .then(res => {
      setProfile(prevState => ({
        ...prevState,
        name: res.data.name,
        email: res.data.email,
        phone: res.data.phone,
      }));
    })
    .catch(err => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const menuSection = (
    <div className="">
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"bjj"} active>
        Brazilian Jiujitsu
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"kyokushin"} active>
        Kyokushin Karate
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"jka"} active>
        JKA Karate
      </Button>
      <Button className="card-menu-trial" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"trial"} active>
        All Arts Trial
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"mma"} active>
        Mixed Martial Arts
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"muaythai"} active>
        Muay Thai
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"kidsjka"} active>
        Kids & Teens
      </Button>
      <Button className="card-menu" variant="primary" size="lg" onClick={(e)=>togglePlan(e)} value={"allaccess"} active>
        All Access
      </Button>
    </div>
  )

  const rentalSection = (
    <>
    <div className="fixing-newsletter">
      <div className="thumbnail center well well-sm text-center">
        <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> THE DOJO AS A SERVICE </h1>
        <p className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Choose a plan that works best for you </p>
        <hr className="hr-large d-none d-md-block"/>
        <CardDeck>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title>Hourly Pass - Shared <br/> <br/><strong><h3> Rs.499 </h3> </strong>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Rent out the space for an hour</ListGroupItem>
              <ListGroupItem>The space will be shared with other athletes/coaches/trainers like yourselves.</ListGroupItem>
              <ListGroupItem>No signup or hidden fees</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
            </Card.Body>
          </Card>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title>Hourly Pass - Private<br/> <br/><strong><h3> Rs.999 </h3> </strong>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Rent out the space for an hour</ListGroupItem>
              <ListGroupItem>Have access to the entire space to yourself including all facilities</ListGroupItem>
              <ListGroupItem>No signup or hidden fees</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
            </Card.Body>
          </Card>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title> Monthly Pass <br/> <br/><strong><h3> Case By Case </h3> </strong>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Month to month agreement</ListGroupItem>
              <ListGroupItem>Access to the entire space & facilities</ListGroupItem>
              <ListGroupItem>A nominal monthly deposit. </ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/samurai-4799.jpeg"/>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    </div>
    <div className="rowcontainer">
     <p className="header-center">
        For more detailed pricing information, email us at hello@thedojoblr.com.
     </p>
    </div>
    <hr className="hr-large d-none d-md-block"/>
    </>
  )

  const muaythaiPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Muay Thai </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.2800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Muay Thai</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={2800} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.8000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Muay Thai</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={8000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.30000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Muay Thai</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={30000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const kyokushinPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Kyokushin Karate </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.3800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults Kyokushin Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={3800} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.10000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults Kyokushin Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={10000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.35000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults Kyokushin Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={35000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const mmaPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Mixed Martial Arts (MMA) </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.3800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Mixed Martial Arts</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={3800} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.10000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Mixed Martial Arts</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={10000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.35000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Mixed Martial Arts</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={35000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const jkaPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> JKA Karate - 2 Days a Week </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.2800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults JKA Karate - Two Days a Week</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={2800} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.8000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults JKA Karate - Two Days a Week</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={8000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.30000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Adults JKA Karate - Two Days a Week</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={30000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
          </Card.Body>
        </Card>
      </CardDeck>
      <hr className="hr-large d-none d-md-block"/>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> JKA Karate - 3 Days a Week </h4>
      <hr className="hr-large d-none d-md-block"/>
        <CardDeck>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.3800 </h3> </strong>
              </Card.Title>
              <Card.Text>
                The more you sweat in training, the less you bleed in combat.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Month to month agreement</ListGroupItem>
              <ListGroupItem>Limited to 1 Art!</ListGroupItem>
              <ListGroupItem>Adults JKA Karate - Three Days a Week</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={3800} active>
                Enroll
              </Button>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
            </Card.Body>
          </Card>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.10000 </h3> </strong>
              </Card.Title>
              <Card.Text>
                Cowards never start. The weak never finish. Winners never quit.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Quarterly agreement</ListGroupItem>
              <ListGroupItem>Limited to 1 Art!</ListGroupItem>
              <ListGroupItem>Adults JKA Karate - Three Days a Week</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={10000} active>
                Enroll
              </Button>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
            </Card.Body>
          </Card>
          <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <Card.Body>
              <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.35000 </h3> </strong>
              </Card.Title>
              <Card.Text>
                Black Belt — A white belt that never quit
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>Annual agreement</ListGroupItem>
              <ListGroupItem>Limited to 1 Art!</ListGroupItem>
              <ListGroupItem>Adults JKA Karate - Three Days a Week</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={35000} active>
                Enroll
              </Button>
              <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
            </Card.Body>
          </Card>
        </CardDeck>
    </>
  )

  const jkaKidsPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> JKA Karate Kids - 2 Days a Week </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.2800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Kids JKA Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={2800} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.8000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Kids JKA Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={8000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/paladin-13999.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.30000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Kids JKA Karate</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={30000} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/zen-49999.jpeg"/>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const bjjPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Brazilian Jiu Jitsu (BJJ) </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.4800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Choice of BJJ - 5 Days a Week </ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={4800} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Shogun (Month - Limited) Pass <br/> <br/><strong><h3> Rs.3800 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Limited to 1 Art!</ListGroupItem>
            <ListGroupItem>Choice of BJJ - 3 Days a Week </ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={4800} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.14000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Unlimited training access to all classes & arts.</ListGroupItem>
            <ListGroupItem>5% discount on Fluvium Merchandize</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={14000} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.40000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Unlimited training access to all BJJ classes!</ListGroupItem>
            <ListGroupItem>10% discount on Fluvium Merchandize</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={50000} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const trialPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> All Arts Trial </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Trial) Pass <br/> <br/><strong><h3> Rs.995 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>One Class Trial Access to All Arts</ListGroupItem>
            <ListGroupItem>MMA / BJJ / Muay Thai / Kyokoshin Karate / JKA Karate</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={995} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )

  const allAccessPlan = (
    <>
      <h4 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Access To All Arts </h4>
      <hr className="hr-large d-none d-md-block"/>
      <CardDeck>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Ronin (Day) Pass <br/> <br/><strong><h3> Rs.700 </h3> </strong>
            </Card.Title>
            <Card.Text>
              It's easy to stand with the crowd. It takes courage to stand alone.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Single day pass</ListGroupItem>
            <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
            <ListGroupItem>No signup or hidden fees</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={700} active>
              Enroll
            </Button>
            <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title>Gladiator Pass <br/> <br/><strong><h3> Rs.5600 </h3> </strong>
            </Card.Title>
            <Card.Text>
              The more you sweat in training, the less you bleed in combat.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Month to month agreement</ListGroupItem>
            <ListGroupItem>Unlimited training access to all classes & arts.</ListGroupItem>
            <ListGroupItem>5% discount on Fluvium Merchandize</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={5600} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Paladin (Quarterly) Pass <br/> <br/><strong><h3> Rs.14000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Cowards never start. The weak never finish. Winners never quit.
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Quarterly agreement</ListGroupItem>
            <ListGroupItem>Unlimited training access to all classes & arts.</ListGroupItem>
            <ListGroupItem>5% discount on Fluvium Merchandize</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={14000} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
        <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Card.Body>
            <Card.Title> Zen (Annual) Pass <br/> <br/><strong><h3> Rs.50000 </h3> </strong>
            </Card.Title>
            <Card.Text>
              Black Belt — A white belt that never quit
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>Annual agreement</ListGroupItem>
            <ListGroupItem>Unlimited training access to all classes & arts!</ListGroupItem>
            <ListGroupItem>10% discount on Fluvium Merchandize</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={50000} active>
              Enroll
            </Button>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  )



  const DisplayPlans = ({selectedPlan}) => {
    console.log(selectedPlan)
    if (selectedPlan === 'bjj') {
      return (<>{bjjPlan}</>);
    } else if (selectedPlan === 'kyokushin') {
      return (<>{kyokushinPlan}</>);
    } else if (selectedPlan === 'trial') {
      return (<>{trialPlan}</>);
    } else if (selectedPlan === 'jka') {
      return (<>{jkaPlan}</>);
    } else if (selectedPlan === 'muaythai') {
      return (<>{muaythaiPlan}</>);
    } else if (selectedPlan === 'kidsjka') {
      return (<>{jkaKidsPlan}</>);
    } else if (selectedPlan === 'mma') {
      return (<>{mmaPlan}</>);
    } else if (selectedPlan === 'allaccess') {
      return (<>{allAccessPlan}</>);
    } else {
    return (
      <div> Choose an Art to check out the membership plans!</div>
    )
    }
  }

  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> TELL US ABOUT YOURSELF </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="rowcontainer">
           <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
             <div className="row">
               <div className="col-md-12">
                <div className="md-form">
                  <input  className="form-control" name="name" value={profile.name} onChange={handleSetProfile}/>
                    <label htmlFor="name">Your Name</label>
                 </div>
               </div>
               <div className="col-md-12">
                <div className="md-form">
                  <input  className="form-control" name="phone" value={profile.phone} onChange={handleSetProfile}/>
                  <label htmlFor="phone">Your Phone Number</label>
                </div>
               </div>
             </div>
             <div className="row">
               <div className="col-md-12">
                <div className="md-form">
                  <input  className="form-control" name="email" value={profile.email} onChange={handleSetProfile}/>
                  <label htmlFor="email">Your Email</label>
                </div>
               </div>
             </div>
           </div>
         </div>
         <hr className="hr-large"/>
         <input className = "btn btn-large btn-primary" type='submit' value={"Checkout " + membershipType} name="Submit" onClick={submitOrder} />
       </Modal.Body>
      </Modal>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> FLEXIBLE PLANS </h1>
            <p className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Join our community. Choose a plan that works best for you </p>
            <hr className="hr-large d-none d-md-block"/>
            {menuSection}
            <hr className="hr-large d-none d-md-block"/>
            <DisplayPlans selectedPlan={selectedPlan}/>
          </div>
        </div>
       <hr className="hr-large d-none d-md-block"/>
       <div className="rowcontainer none">
        <p>
           Scan the QR Code to enroll into The Dojo. Please do send the payment confirmation image/screenshot to hello@thedojoblr.com for backend verification so we
           can update your membership details!
        </p>
       </div>
       {rentalSection}
     </div>
    </main>
  );
}

// <Card className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
//   <Card.Body>
//     <Card.Title> Test Pass <br/> <br/><strong><h3> Rs.1 </h3> </strong>
//     </Card.Title>
//     <Card.Text>
//       It's easy to stand with the crowd. It takes courage to stand alone.
//     </Card.Text>
//   </Card.Body>
//   <ListGroup className="list-group-flush">
//     <ListGroupItem>Single day pass</ListGroupItem>
//     <ListGroupItem>Access to all the classes throughout the day</ListGroupItem>
//     <ListGroupItem>No signup or hidden fees</ListGroupItem>
//   </ListGroup>
//   <Card.Body>
//     <Button className="" variant="primary" size="lg" onClick={!isAuthenticated ? () => loginWithRedirect({}) : (e)=>toggleMembership(e)} value={1} active>
//       Enroll
//     </Button>
//     <Image className="qrcode none" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/ronin-899.jpeg"/>
//   </Card.Body>
// </Card>
{/* <div className="rowcontainer">
<h2 className="header-center"> PROMOTIONS </h2>
<p>
  We're currently running the below mentioned promotions to facilitate & provide martial arts training at discounted rates! Eligible candidates, Please
  email your credentials along with ID cards in order to avail the same :-
</p>
<ul>
  <li> First responders such as police officers, army veterans/soldiers, doctors, nurses, etc. get 25% off on the membership plans</li>
  <li> Students get 25% off on membership plans on submission of valid ID cards</li>
  <li> Invite your friend or family and if they enroll, both you and they get 15 days added to your membership plan! </li>
</ul>
</div>
<hr className="hr-large d-none d-md-block"/>
<div className="rowcontainer">
<h2 className="header-center"> MEMBERSHIP FREEZE POLICY </h2>
<p>
  We have a membership freeze policy in place. You can pause your membership as per the below limits as many times as you'd like:- <br/>
  <ul>
    <li> Palading Pass: 15 Days</li>
    <li> Zen Pass: 45 Days </li>
  </ul>
</p>
</div> */}



export default Membership
