import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Attendance.css';
import { useHistory } from 'react-router-dom';
import {Modal} from 'react-bootstrap';

function Enquiry(props) {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(0);
  const history = useHistory();
  const [newCustomer, setNewCustomer] = useState({name: "",phone:"",email:"", art:""});

  useEffect(() => {
    axios.get('/user/all-users')
    .then(res => {
      console.log(res.data)
      setUsers(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const handleCustomer = e => {
    const { name, value } = e.target;
    setNewCustomer(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const customerUpdate = (event) => {
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    console.log(newCustomer)
    axios.post('/user?tenantId=thedojo', {
      name: newCustomer.name,
      phone: newCustomer.phone,
      email: newCustomer.email,
      art: newCustomer.art,
      startDate: Date.now(),
      adminPage: 1
     })
    .then(res => {
      console.log("Successfully added to database")
      setNewCustomer(prevState => ({
          ...prevState,
          name: "",
          phone: "",
          email: "",
      }));
      console.log(res.data)
      users.push(res.data);
    })
    .catch(err => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }


  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Succesfully Created New User </h1>
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> CREATE NEW USER </h1>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
                <div>
                  <input  className="form-control" name="name" value={newCustomer.name || ""} onChange={handleCustomer} />
                  <label htmlFor="name">Name</label>
                </div>
                <div>
                  <input  className="form-control" name="phone" value={newCustomer.phone || ""}  onChange={handleCustomer}/>
                  <label htmlFor="phone">Phone</label>
                </div>
                <div>
                  <input  className="form-control" name="email" value={newCustomer.email || ""}  onChange={handleCustomer}/>
                  <label htmlFor="email">Email</label>
                </div>
                <div className="md-form">
                  <select className="form-control" name="art" value={newCustomer.art} onChange={handleCustomer}>
                    <option value="">--</option>
                    <option value="BJJ">BJJ</option>
                    <option value="Kyokushin Karate">Kyokushin Karate</option>
                    <option value="MMA"> MMA </option>
                    <option value="Kids Karate">Kids Karate</option>
                    <option value="All Access">All Access</option>
                  </select>
                  <label htmlFor="art">Your Art</label>
                </div>
                <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={customerUpdate}  disabled={disabled} />
              </div>
           </div>
         </div>
       </div>
       <hr className="hr-large d-none d-md-block"/>
   </div>
</main>
  );
}

export default Enquiry
