import {React, useState, useEffect } from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import {MENUOPTIONS} from '../shared/menuOptions.js';
import '../css/Header.css';
import axios from 'axios'
// import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from "../react-auth0-spa";

function Header(props) {
  const [menuOptions] = useState(MENUOPTIONS)
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [ role, setRole] = useState("");

  useEffect(() => {
    if(user) {
      console.log(user)
      axios.get('/user?email=' + user.email)
      .then(res => {
        setRole(res.data.role)
      })
      .catch(err => console.log(err));
    }

  }, []);

  return (
    <>
      <header>
        <Navbar bg="dark" expand="lg" variant="dark">
          <Navbar.Brand href="/">
            <img className="navbar-brand-img" src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/thedojo-logo-nobg.png"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {menuOptions.map((menu) => (
                <Nav.Link key = {menu.id} href={menu.link}> {menu.name} </Nav.Link>
              ))}
              { isAuthenticated && role === "admin" ?
                <NavDropdown title="Account" id="dropdown-menu-align-right" menualign="left" drop="down" align="center">
                  <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Item href="/users"> Members </NavDropdown.Item>
                  <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
                  <NavDropdown.Item href="/enquiries">Enquiries</NavDropdown.Item>
                  <NavDropdown.Item href="/new-member">Add Member</NavDropdown.Item>
                  <NavDropdown.Item href="/attendance-info">Attendance</NavDropdown.Item>
                  <NavDropdown.Item href="/video">Video</NavDropdown.Item>
                  <NavDropdown.Item onClick={!isAuthenticated ? () => loginWithRedirect({}) : () => logout({ returnTo: window.location.origin })}> {!isAuthenticated ? "Logout": "Logout"}</NavDropdown.Item>
                </NavDropdown>
                :
                isAuthenticated ?
                  <NavDropdown title="Account" id="dropdown-menu-align-right" menualign="left" drop="down" align="center">
                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item onClick={!isAuthenticated ? () => loginWithRedirect({}) : () => logout({ returnTo: window.location.origin })}> {!isAuthenticated ? "Logout": "Logout"}</NavDropdown.Item>
                  </NavDropdown>
                  :
                ""
              }
              <Nav.Link onClick={!isAuthenticated ? () => loginWithRedirect({}) : () => logout({ returnTo: window.location.origin })}> {!isAuthenticated ? "Login" : ""} </Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Navbar>

      </header>
    </>
  );
}

export default Header
// <p className="logout-text"> {isAuthenticated ? "Logout" : "Login"} </p>
