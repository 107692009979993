import {React, useState, useEffect } from 'react';
import {Button} from 'react-bootstrap'
import axios from 'axios'
import '../css/Table.css';
import { useHistory, Link } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";
import { CSVLink } from "react-csv";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import {ARTSOPTIONS} from '../shared/artsOptions.js';
import {BATCHESOPTIONS} from '../shared/batchOptions.js';

function Users(props) {
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(0);
  const { user, loading } = useAuth0();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [newCustomer, setNewCustomer] = useState({name: "",phone:"",email:"", art:"", batch: ""});
  const [isEditing, setEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [role, setRole] = useState("")
  const [show, setShow] = useState(false);
  const [artOptions] = useState(ARTSOPTIONS)
  const [batchOptions] = useState(BATCHESOPTIONS)

  useEffect(() => {
    axios.get('/user?email=' + user.email)
    .then(res => {
      setRole(res.data.role)
    })
    .catch(err => console.log(err));

    axios.get('/user/all-users/')
    .then(res => {
      setUsers(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const handleCustomer = e => {
    const { name, value } = e.target;
    setNewCustomer(prevState => ({
        ...prevState,
        [name]: value
    }));
  };


  const customerUpdate = (event) => {
    event.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
    }.bind(this),10000);
    axios.post('/user?tenantId=thedojo', {
      name: newCustomer.name,
      phone: newCustomer.phone,
      email: newCustomer.email,
      art: newCustomer.art,
      batch: newCustomer.batch,
      startDate: Date.now(),
      adminPage: 1
     })
    .then(res => {
      setShow(false)
      console.log("Successfully added to database")
      setNewCustomer(prevState => ({
          ...prevState,
          name: "",
          phone: "",
          email: "",
          art: ""
      }));
      console.log(res.data)
      users.push(res.data);
    })
    .catch(err => console.log(err));
  };

  const editRowStatusFunc = (e) => {
    setEditing(true);
    setEditRow(e.target.name);
    // console.log(leadEdit)
  }

  const addMember = (e) => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const updatePaymentStatus = (e) => {
    axios.put('/user?email=' + editRow, {
      paymentStatus: paymentStatus,
    })
    .then(res => {
      console.log(res.data)
      var foundIndex = users.findIndex(x => x.email == res.data.email);
      users[foundIndex] = res.data;
    })
    .catch(err => console.log(err));
  }

  const renewMembership = (e) => {
    console.log(e.target.name)
    axios.post('/email/membership-renewal', {
      email: e.target.name
    })
    .then(res => {
      console.log("Sucesfully sent reminder email")
    })
    .catch(err => console.log(err));
  }

  const newMemberModalSection = (
    <>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
        <div className="row">
          <div className="col-md-6">
            <div className="md-form">
              <input  className="form-control" name="name" value={newCustomer.name || ""} onChange={handleCustomer} /> 
              <label htmlFor="name">Name</label>
              <div className="invalid-feedback">Enter Your Name!</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="md-form">
              <input  className="form-control" name="phone" value={newCustomer.phone || ""}  onChange={handleCustomer}/>
              <label htmlFor="phone">Phone Number</label>
              <div className="invalid-feedback">Please Enter a Valid Phone Number</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="md-form">
              <input  className="form-control" name="email" value={newCustomer.email || ""}  onChange={handleCustomer}/>
              <label htmlFor="email">Your Email</label>
              <div className="invalid-feedback">Please Provide a Valid Email ID!</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="md-form">
              <select className="form-control" name="batch" value={newCustomer.batch} onChange={handleCustomer}>
                <option value="">--</option>
                {batchOptions.map((batches) => (
                  <option value={batches.batch}> {batches.batch} </option>
                ))}
              </select>
              <label htmlFor="Batch">Batch</label>
              <div className="invalid-feedback">Please choose your Batch!</div>
              </div>
            </div>
          <div className="col-md-6">
            <div className="md-form">
              <select className="form-control" name="art" value={newCustomer.art} onChange={handleCustomer}>
                <option value="">--</option>
                {artOptions.map((arts) => (
                  <option value={arts.name}> {arts.name} </option>
                ))}
              </select>
              <label htmlFor="Art">Art</label>
              <div className="invalid-feedback">Please choose your art!</div>
              </div>
            </div>
        </div>

          <input className = "btn btn-large btn-primary" type='submit' value='&#10004;' onClick={customerUpdate}  disabled={disabled} />
        </div>
    </>
  )

  const userHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Contact Information </th>
          <th scope="col" data-sortable='true'> Membership Information </th>
          <th scope="col" data-sortable='true'> Start Date </th>
          <th scope="col" data-sortable='true'> End Date </th>
          <th scope="col" data-sortable='true'> Status </th>
          <th scope="col" data-sortable='true'> Emails </th>
        </tr>
      </thead>
    </>
  )

  const usersDisplay = (
  <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 container b2corders">
    <table className='table row-number'>
      {userHeader}
      <tbody >
      {users.map((user, i) => (
          <tr key={user._id} className={`${!user.membership ? "" : (moment(new Date()).isAfter(moment(user.membership.endDate))) ? "row-space date-crossed" : "row-space"}`}>
            <td className="section-hide"> { i+1 }</td>
            <td className="section-hide"> 
              <Link to={`/users/${user._id}`}> { user.name } </Link> 
              <br/> { user.phone } 
              <br/> <Link to={`/users/${user._id}`}> { user.email } </Link>
            </td>
            <td className="section-hide"> { user.art } <br/>  { user.batch }</td>
            <td className="section-hide"> { moment(user.createdAt).format('DD-MM-YYYY') }</td>
            <td className="section-hide"> { user.membership ? moment(user.membership.endDate).format('DD-MM-YYYY') : "NO ORDER PLACED" }</td>
            <td className="section-hide"> { !user.membership ? "" : moment(new Date()).isAfter(moment(user.membership.endDate)) ? "INACTIVE" : "ACTIVE"}</td>
            <td className="section-hide">
              <Button as="input" type="submit" onClick={renewMembership} name={user.email} value="Reminder Mail" />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> Enter Member Details </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {newMemberModalSection}
         <hr className="hr-large"/>
       </Modal.Body>
      </Modal>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> USERS </h1>
            <div className="bulk-update section-hide">
              <CSVLink  className="btn btn-large btn-primary bulk-update-order-button" data={users.map( user => ({
                Name: user.name,
                Phone: user.phone,
                Email: user.email,
                StartDate: moment(user.createdAt).format('DD-MM-YYYY'),
                endDate: `${ user.membership ? moment(user.membership.endDate).format('DD-MM-YYYY') : "NO ORDER PLACED" }`,
                Status:  `${!user.membership ? "" : moment(new Date()).isAfter(moment(user.membership.endDate)) ? "INACTIVE" : "ACTIVE"}`,
              }))} > Export Users </CSVLink>
              <input className="btn btn-large btn-primary bulk-update-order-button add-member" onClick={addMember} type='submit' value="Add New Member"/>

            </div>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {role === "admin" ? usersDisplay : "Page only Accessible By Admins"}
            </div>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default Users