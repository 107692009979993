export const BATCHESOPTIONS =
[
  {
    id: 0,
    batch: "7:15-8:30 / AM / 5 Days / BJJ",
  },
  {
    id: 1,
    batch: "7:15-8:30 / AM / 3 Days / BJJ",
  },
  {
    id: 2,
    batch: "7:00-8:00 / AM / 2 Days / JKA",
  },
  {
    id: 3,
    batch: "11:00-12:00 / AM / 2 Days / MT",
  },
  {
    id: 4,
    batch: "4:15-5:15 / PM / 2 Days / JKA",
  },
  {
    id: 5,
    batch: "5:30-6:30 / PM / 2 Days / JKA",
  },
  {
    id: 6,
    batch: "6:30-7:30 / PM / 3 Days / MMA",
  },
  {
    id: 7,
    batch: "7:15-8:30 / PM / 3 Days / KK",
  },
  {
    id: 8,
    batch: "8:00-9:00 / PM / 3 Days / JKA",
  },
  {
    id: 9,
    batch: "All Access",
  },

]
