import {React } from 'react';
import '../css/Schedule.css';

function Schedule(props) {

  return (
    <main>
      <div className="container">
        <div className="table-responsive">
          <table className="table table-bordered text-center">
            <thead>
              <tr className="bg-light-gray">
                <th className="text-uppercase">Time
                </th>
                <th className="text-uppercase">Monday</th>
                <th className="text-uppercase">Tuesday</th>
                <th className="text-uppercase">Wednesday</th>
                <th className="text-uppercase">Thursday</th>
                <th className="text-uppercase">Friday</th>
                <th className="text-uppercase">Saturday</th>
                <th className="text-uppercase">Sunday</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td className="align-middle">06:00am</td>
                <td>
                  <p className="bg-purple padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Kyokushin Karate</p>
                  <div className="margin-10px-top font-size14">06:00-07:00</div>
                  <div className="font-size13 text-light-gray">Vinayak Shetty</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-purple padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Kyokushin Karate</p>
                  <div className="margin-10px-top font-size14">06:00-07:00</div>
                  <div className="font-size13 text-light-gray">Vinayak Shetty</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-purple padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Kyokushin Karate</p>
                  <div className="margin-10px-top font-size14">06:00-07:00</div>
                  <div className="font-size13 text-light-gray">Vinayak Shetty</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
              </tr>

              <tr>
                <td className="align-middle">07:00am</td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13">Jiujitsu Advanced<br/> (Gi)</p>
                  <div className="margin-10px-top font-size14">7:15-8:30</div>
                  <div className="font-size13 text-light-gray">Uma Maheshwar Reddy</div>
                </td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Jiujitsu Advanced <br/> (No-Gi)</p>
                  <div className="margin-10px-top font-size14">7:15-8:30</div>
                  <div className="font-size13 text-light-gray">Uma Maheshwar Reddy</div>
                </td>

                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Jiujitsu Advanced <br/> (Gi)</p>
                  <div className="margin-10px-top font-size14">7:15-8:30</div>
                  <div className="font-size13 text-light-gray">Uma Maheshwar Reddy</div>
                </td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Jiujitsu Advanced <br/> (No-Gi)</p>
                  <div className="margin-10px-top font-size14">7:15-8:30</div>
                  <div className="font-size13 text-light-gray">Uma Maheshwar Reddy</div>
                </td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Jiujitsu Advanced <br/> (Gi)</p>
                  <div className="margin-10px-top font-size14">7:15-8:30</div>
                  <div className="font-size13 text-light-gray">Uma Maheshwar Reddy</div>
                </td>
                <td>
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">07:00 - 08:00</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
                <td>
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens)</p>
                  <div className="margin-10px-top font-size14">07:00 - 08:00</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
              </tr>
              <tr>
                <td className="align-middle">09:00AM</td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
              </tr>
              <tr>
                <td className="align-middle">11:00AM</td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-turqoise padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> Striking <br/> Boxing / Muay Thai </p>
                  <div className="margin-10px-top font-size14">11:00 - 12:00</div>
                  <div className="font-size13 text-light-gray">Avilash</div>
                </td>
                <td>
                <p className="bg-turqoise padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> Striking <br/> Boxing / Muay Thai </p>
                  <div className="margin-10px-top font-size14">11:00 - 12:00</div>
                  <div className="font-size13 text-light-gray">Avilash</div>
                </td>
              </tr>
              <tr>
                <td className="align-middle">16:30PM</td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">16:15 - 17:15</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td >
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">16:15 - 17:15</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
                <td>
                  <p className="bg-pink padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">16:15 - 17:15</div>
                  <div className="font-size13 text-light-gray">Sunita</div>
                </td>
                <td>
                  <p className="bg-pink padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">16:15 - 17:15</div>
                  <div className="font-size13 text-light-gray">Sunita</div>
                </td>
              </tr>
              <tr>
                <td className="align-middle">17:30PM</td>
                <td >
                  <p className="bg-pink padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">17:00 - 18:00</div>
                  <div className="font-size13 text-light-gray">Sunita</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">17:30 - 18:30</div>
                  <div className="font-size13 text-light-gray">Ray</div>
                </td>
                <td>
                  <p className="bg-pink padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">17:00 - 18:00</div>
                  <div className="font-size13 text-light-gray">Sunita</div>                
                </td>
                <td>
                  <p className="bg-lightred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Kids/Teens) </p>
                  <div className="margin-10px-top font-size14">17:30 - 18:30</div>
                  <div className="font-size13 text-light-gray">Ray</div>
                </td>
                <td>
                  <p className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Mixed Martial Arts (MMA) </p>
                  <div className="margin-10px-top font-size14">17:30-18:30</div>
                  <div className="font-size13 text-light-gray"> Avilash </div>

                </td>
                <td className="bg-light-gray">
                </td>

              </tr>
              <tr>
                <td className="align-middle">18:30PM</td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13">Jiujitsu Begginers<br/> (Gi/Nogi)</p>
                  <div className="margin-10px-top font-size14">18:30-19:30</div>
                  <div className="font-size13 text-light-gray">Jishnu</div>
                </td>
                <td>
                  <p className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Mixed Martial Arts (MMA)</p>
                  <div className="margin-10px-top font-size14">18:30-19:30</div>
                  <div className="font-size13 text-light-gray">Avilash</div>
                </td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13">Jiujitsu Begginers<br/> (Gi/Nogi)</p>
                  <div className="margin-10px-top font-size14">18:30-19:30</div>
                  <div className="font-size13 text-light-gray">Jishnu</div>
                </td>
                <td>
                  <p className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13">Mixed Martial Arts (MMA)</p>
                  <div className="margin-10px-top font-size14">18:30-19:30</div>
                  <div className="font-size13 text-light-gray">Avilash</div>
                </td>
                <td>
                  <p className="bg-sky padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13">Jiujitsu Begginers<br/> (Gi/Nogi)</p>
                  <div className="margin-10px-top font-size14">18:30-19:30</div>
                  <div className="font-size13 text-light-gray">Jishnu</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td className="bg-light-gray">
                </td>
              </tr>
              <tr>
                <td className="align-middle">19:15pm</td>
                <td>
                  <p className="bg-darkred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Kenzi</div>
                </td>
                <td>
                  <p className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
                <td>
                  <p className="bg-darkred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Kenzi</div>
                </td>
                <td className="bg-light-gray">
                </td>
                <td>
                  <p className="bg-darkred padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Kenzi</div>
                </td>
                <td>
                  <p className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
                <td>
                  <p className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13"> JKA Karate (Adults) </p>
                  <div className="margin-10px-top font-size14">19:30-20:30</div>
                  <div className="font-size13 text-light-gray">Partha</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default Schedule
