import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Table.css';
import { useHistory, Link } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";
import moment from 'moment';

function Users(props) {
  const [orders, setOrders] = useState([]);
  const [disabled, setDisabled] = useState(0);
  const { user } = useAuth0();
  const [role, setRole] = useState("")

  useEffect(() => {
    console.log(user)
    console.log("Front end working right");
    axios.get('/user?email=' + user.email)
    .then(res => {
      console.log(res.data)
      setRole(res.data.role)
    })
    .catch(err => console.log(err));

    axios.get('/order/all-orders/')
    .then(res => {
      setOrders(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const exportOrders = (e) => {
    e.preventDefault();
    setDisabled(1)
    setTimeout(function(){
     setDisabled(0);
   },10000);
    axios.get('/order/export', {
     responseType: 'arraybuffer',
     headers: {
       'Accept': 'csv/txt'
     }
   })
    .then(res => {
      // console.log(res)
      const blob = new Blob([res.data], {type: 'csv/txt'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `orders.csv`
      link.click()
    })
    .catch(err => console.log(err.response));
  }

  const orderHeader = (
    <>
      <thead>
        <tr>
          <th className="section-hide"> No. </th>
          <th scope="col" data-sortable='true'> Order Details </th>
          <th scope="col" data-sortable='true'> Contact Info </th>
          <th scope="col" data-sortable='true'> Amount </th>
          <th scope="col" data-sortable='true'> Art </th>
          <th scope="col" data-sortable='true'> Batch </th>
          <th scope="col" data-sortable='true'> Membership </th>
        </tr>
      </thead>
    </>
  )


  const ordersDisplay = (
  <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 container b2corders">
    <table className='table row-number'>
      {orderHeader}
      <tbody >
      {orders.map((order, i) => (
          <tr key={order._id}>
            <td className="section-hide"> { i+1 }</td>
            <td className="section-hide"> { moment(order.orderDate).format('DD-MM-YYYY') } <br/>  { order.orderId }</td>
            <td className="section-hide">
              <Link to={`/users/${order._id}`}> { order.name } </Link> <br/>
              {order.email} <br/>
              {order.phone}
            </td>
            <td className="section-hide"> {order.amount} </td>
            <td className="section-hide"> { order.art }</td>
            <td className="section-hide"> { order.batch }</td>
            <td className="section-hide"> { order.membership }</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <main>
      <div className="contact-form">
        <div className="fixing-newsletter">
          <div className="thumbnail center well well-sm text-center">
            <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ORDERS </h1>
            <input className="btn bulk-update-button btn-large" onClick={exportOrders} type='submit' value={disabled ? 'Exporting Orders...' : 'Export Orders' }/>
            <hr className="hr-large d-none d-md-block"/>
            <div className="rowcontainer">
              {role === "admin" ? ordersDisplay : "Page only Accessible By Admins"}
            </div>
          </div>
        </div>
        <hr className="hr-large"/>
      </div>
    </main>
  );
}

export default Users
