import React, {useRef} from 'react';
import VideoJS from '../components/VideoJS.js'
import ReactPlayer from 'react-player'
import {Card, CardDeck, ListGroup, ListGroupItem, Button, Modal, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom'

import '../css/HomePage.css';

function VideoStreaming(props) {

  const playerRef = React.useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    height: '600px',
    aspectRatio: "4:3",
    fluid: true,
    sources: [{
      src: 'https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8',
      type: 'application/x-mpegURL'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return (
    <>
      <div>Rest of app here</div>

      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

      <div>Rest of app here</div>
    </>
  );
}

export default VideoStreaming

// <>
//   <div>Rest of app here</div>
//
//   <ReactPlayer url='https://dojo-classes.s3.ap-south-1.amazonaws.com/darce.mp4' />
//
//   <div>Rest of app here</div>
// </>
