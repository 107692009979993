import React, {useRef, useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../css/VideoCatalogue.css';
import '../css/Privates.css';
import {VIDEOS} from '../shared/videoMetaData.js';
import VideoJS from '../components/VideoJS.js';
import {Link} from 'react-router-dom';
import {Card, CardDeck, ListGroup, ListGroupItem, Button, Modal, Image} from 'react-bootstrap';
import '../css/HomePage.css';

function Videos(props) {
  const [videos] = useState(VIDEOS)

  const playerRef = React.useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    height: '600px',
    aspectRatio: "4:3",
    fluid: true,
    sources: [{
      src: 'https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8',
      type: 'application/x-mpegURL'
    }]
  }

  useEffect(() => {
    console.log(videos)
  }, []);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const privateSection = (
    <>
    <div className="fixing-newsletter">
      <div className="thumbnail center well well-sm text-center">
        {videos.map((video) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 grid-item">
            <div className="video text-center video-wrapper">
              <iframe width="350" height="250" src={video.url} title="Video Player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p className="blog-post-category text-center space-top">BJJ</p>
            <p className="text-center blog-post-name">{video.name}<br/>Uma Maheshwar Reddy</p>
          </div>
        ))}
      </div>
    </div>
    </>
  )

  return (
    <main>
      <div className="container">
        <div>
          <h1 className="index-header"> PRIVATE CLASSES </h1>
        </div>
        {privateSection}
      </div>
    </main>
  );
}

export default Videos
