import './App.css';
import { Router, Switch, Route } from 'react-router-dom'
// import {HashRouter} from 'react-router-dom'
// import ProtectedRoute from "./auth/protected-route";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth0 } from "./react-auth0-spa";
import Header from './components/Header'
import Footer from './components/Footer'
import Loading from './components/Loading'
import Home from './pages/HomePage'
import PrivacyPolicy from './pages/PrivacyPolicy'
import RefundPolicy from './pages/RefundPolicy'
import Enquiry from './pages/Enquiry'
import CreateUser from './pages/CreateUser'
import Schedule from './pages/Schedule'
import Privates from './pages/Privates'
import Membership from './pages/Membership'
import NewMember from './pages/NewMember'
import GymRules from './pages/GymRules'
import Artists from './pages/Artists'
import Arts from './pages/Arts'
import TheDojoExperience from './pages/TheDojoExperience'
import ThankYouContact from './pages/ThankYouContact'
import ThankYouNewsletter from './pages/ThankYouNewsletter'
import OrderConfirmation from './pages/OrderConfirmation'
import Profile from './pages/Profile'
import AllUsers from './pages/AllUsers'
import AllEnquiries from './pages/AllEnquiries'
import AllOrders from './pages/AllOrders'
import AdminUserProfile from './pages/AdminUserProfile'
import ContactInfo from './pages/ContactInfo'
import TermsOfService from './pages/TermsOfService'
import VideoStreaming from './pages/VideoStreaming'
import VideoCatalogue from './pages/VideoCatalogue'
import Finzy from './pages/Finzy'
import Attendance from './pages/Attendance'
import Dashboard from './pages/Dashboard'
import AttendanceInfo from './pages/AttendanceInfo'
import FAQ from './pages/FAQ'
import history from "./utils/history";
// import { AuthContext } from "./context/auth";


function App(props) {

  const { loading } = useAuth0();

  if (loading) {
    return <Loading/>;
  }

  return (
    <Router history={history}>
      <div className="App">
        <Header/>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/privacy-policy' component={PrivacyPolicy}/>
            <Route exact path='/refund-policy' component={RefundPolicy}/>
            <Route exact path='/enquiry' component={Enquiry}/>
            <Route exact path='/schedule' component={Schedule}/>
            <Route exact path='/privates' component={Privates}/>
            <Route exact path='/membership' component={Membership}/>
            <Route exact path='/gym-rules' component={GymRules}/>
            <Route exact path='/the-artists' component={Artists}/>
            <Route exact path='/the-arts' component={Arts}/>
            <Route exact path='/finzy' component={Finzy}/>
            <Route exact path='/the-dojo-experience' component={TheDojoExperience}/>
            <Route exact path='/thank-you-for-contacting-us' component={ThankYouContact}/>
            <Route exact path='/thank-you-for-signing-up' component={ThankYouNewsletter}/>
            <Route exact path='/order-confirmation' component={OrderConfirmation}/>
            <Route exact path='/terms-of-service' component={TermsOfService}/>
            <Route exact path='/frequently-asked-questions' component={FAQ}/>
            <Route exact path='/check-in' component={Attendance}/>
            <Route exact path='/new-member' component={NewMember}/>
            <Route exact path='/attendance-info' component={AttendanceInfo}/>
            <Route exact path='/video' component={VideoCatalogue}/>
            <Route path='/videos/:video' component={VideoStreaming}/>
            <Route path='/dashboard' component={Dashboard}/>
            <Route exact path='/create-user' component={CreateUser}/>
            <PrivateRoute exact path='/profile' component={Profile}/>
            <PrivateRoute exact path='/orders' component={AllOrders}/>
            <PrivateRoute exact path='/users' component={AllUsers}/>
            <PrivateRoute exact path='/enquiries' component={AllEnquiries}/>
            <PrivateRoute exact path='/new-member' component={NewMember}/>
            <PrivateRoute exact path='/contact-info' component={ContactInfo}/>
            <PrivateRoute path='/users/:email' component={AdminUserProfile}/>
          </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
