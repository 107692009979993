export const VIDEOS =
[
  {
    id: 0,
    name: "Darce",
    url: "https://www.youtube.com/embed/LLkFuJlmrLQ?si=9wOrQ-kRG7j9WP6F",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
  {
    id: 1,
    name: "Darce",
    url: "https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
  {
    id: 2,
    name: "Darce",
    url: "https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
  {
    id: 3,
    name: "Darce",
    url: "https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
  {
    id: 4,
    name: "Darce",
    url: "https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
  {
    id: 5,
    name: "Darce",
    url: "https://dojo-classes-output.s3.ap-south-1.amazonaws.com/IMG_0362.m3u8",
    description:"",
    tags: [],
    thumbnail:"https://www.freeimages.com/photo/leather-link-texture-1538412",
    category:"BJJ"
  },
]
