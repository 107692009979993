import {React} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../css/Schedule.css';
import '../css/Privates.css';
import {Card, CardDeck, ListGroup, ListGroupItem, Button, Modal, Image} from 'react-bootstrap';

import '../css/HomePage.css';

function Arts(props) {

  const privateSection = (
    <>
    <div className="fixing-newsletter">
      <div className="thumbnail center well well-sm text-center">
        <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> PRIVATES </h1>
        <p className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> <strong> Choose an Art </strong> </p>
        <hr className="hr-large d-none d-md-block"/>
        <CardDeck className="privates-cardDeck">
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Brazilian Jiu Jitsu
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Boxing
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Kyokushin Karate
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Kettle Bell
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Tai Chi
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Bo Staff
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Bokken / Sword Training
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Wim Hoff Method
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="privates-card">
            <Card.Body>
              <Card.Title className="privates-card-title"> Functional Patterns
              </Card.Title>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
      <div className="rowcontainer">
       <p className="header-center">
          For more detailed pricing information, email us at hello@thedojoblr.com.
       </p>
      </div>
      <hr className="hr-large d-none d-md-block"/>

    </div>
    </>
  )

  return (
    <main>
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/arts/kyokushin-history.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src="https://s3.ap-south-1.amazonaws.com/thedojoblr.com/arts/bjj-history-3.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container">
        <div>
          <h1 className="index-header"> PRIVATE CLASSES </h1>
        </div>
        <div>
          <h4> How you will benefit from personal training one-on-one with an Instructor </h4>
          <p>
            <strong>Your Individual Needs:</strong> Your private lesson will be designed specifically to suit you! Your Personal Instructor will tailor a program for your; body type, stature, age, sex, physical fitness level, temperament, and goals.
            <br/>
            <br/>
            <strong>You Can Specialise </strong> in the area of Martial Arts or Self defence that interests you.; The Dojo offer specialist Instructors to cover your needs. From; Boxing, MMA, Jiu Jitsu, Kyokushin Karate, Bo Staff, Grappling (Floor/Stand up), Tai Chi, and Kettle Bell.
            <br/>
            <br/>
            <strong>Your Highest Standard in the Shortest Possible Time:</strong> The Dojo Instructors won’t let you pick up any bad habits because of poor instruction. In fact you will be constantly monitored and pushed to reach your best.
            <br/>
            <br/>
            <strong> You Will Learn From a Highly Qualified Instructors: </strong> The Dojo Instructors are qualified certificate level sports coach’s, with either a minimum of 5 years and National Level competitive experience. Professional instruction, Passionate martial artists.
            <br/>
            <br/>
            <strong>Times Convenient to You: </strong> The Dojo will do our best to give you a time or day convenient to you.
            <br/>
            <br/>
            <strong> Versatility of Styles Available: </strong> Specialties include Self Defence, Freestyle Martial Arts, Muay Thai Kickboxing, Jiu Jitsu, Grappling and Kyokushin Karate. If you are unsure about anything to do with Martial Arts, give us a call. We’ll be happy to help you.
            <br/>
            <br/>
            <strong> Affordable Rates: </strong> Cost effective, especially when time and quality are important to you. If you’re on a budget, you can share session with a friend and half the cost. Great rates for private groups of friends or colleagues available on request.
            <br/>
            <br/>
            <strong> In Person Visits: </strong> We can arrange for the instructor to visit you at your home and bring neccessary equipment like mats, mitts, etc.
            <br/>
            <br/>
            <strong> Latest Equipment: </strong> The Dojo is a world class purpose built facility, fully matted with various apparatus, lounge, viewing area & Pro-Shop. A wide variety of equipment from traditional to modern high tech measured tools.
            <br/>
            <br/>
            <strong> The Dojo Instructors </strong> will put your needs first. They are safe, caring and responsible trainers who will get the very best from you. Make the difference to your life… start today.
            <br/>
            <br/>
          </p>
        </div>
        {privateSection}

      </div>
    </main>
  );
}

export default Arts
