import {React} from 'react';
import {Image, Button} from 'react-bootstrap';
import '../css/HomePage.css';

function Finzy(props) {

  return (
    <main>
      <div className="full-page">
        <div className="text-over-image">
          <h1> The Dojo - Finzy </h1>
          <p className="white"> We've partnered with Finzy a Fin-Tech firm that provides hassle free no interest EMI's. Our annual plan can be divided to 3-12 interest free payments
          at absolutely no extra cost for you. This way we hope to ease the burden to those who'd like to commit full time. Click on the button below to be redirected
          to Finzy's portal to check your eligibility.
          </p>
          <Button href="https://finzy.com/borrow/eligibility-check?channel-partner-id=61dfb00673dc6f44131eafbe"> Check Eligibility With Finzy </Button>

        </div>
      </div>
    </main>
  );
}

export default Finzy
