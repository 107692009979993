import {React, useState, useEffect } from 'react';
import axios from 'axios'
import '../css/Enquiry.css';
import '../css/Receipt.css';
import { useAuth0 } from "../react-auth0-spa";
import moment from 'moment';
import {Modal} from 'react-bootstrap';

function Profile(props) {
  const [profile, setProfile] = useState({name: "",email: "",phone:"",bio:"",art:"", batch:"", endDate:"", startDate:"", role:""});
  const [address, setAddress] = useState({street1: "", city:"",state:"",zip:"",country:""})
  const [receipt, setReceipt] = useState({memberName:"", email:"", phone:"", membership: "", amount:"",id:"", orderDate:""})
  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([])
  const [membershipHistory, setMembershipHistory] = useState([])
  const { user } = useAuth0();

  useEffect(() => {
    axios.get('/user?email=' + user.email)
    .then(res => {
      console.log(res.data);
      setProfile({
        id: res.data._id || "",
        name: res.data.name || "",
        email: res.data.email || "",
        phone: res.data.phone || "",
        bio: res.data.bio || "",
        art: res.data.art || "",
        batch: res.data.batch || "",
        endDate: res.data.membership.endDate || "",
        startDate: res.data.createdAt || "",
      })
      if(res.data.membershipHistory) {
        setMembershipHistory((res.data.membershipHistory))
      }
      if(res.data.address) {
        setAddress({
          street1: res.data.address.street1,
          city: res.data.address.city,
          state: res.data.address.state,
          zip: res.data.address.zip,
          country: res.data.address.country,
        })
      }
    })
    .catch(err => console.log(err));
    axios.get('/order?email=' + user.email)
    .then(res => {
      console.log(res.data)
      setOrders(res.data)
    })
    .catch(err => console.log(err));
  }, []);

  const handleSetProfile = e => {
    const { name, value } = e.target;
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSetAddress = e => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const viewReceipt = (i) => {
    setShow(true)
    console.log(i)
    console.log(orders[i].membership)
    setReceipt({
      memberName: profile.name || "",
      email: profile.email || "",
      phone: profile.phone || "",
      membership: orders[i].membership || "",
      amount: orders[i].amount || "",
      id: orders[i].orderId || "",
      orderDate: orders[i].orderDate || "",
    })
  };

  const handleClose = () => {
    setShow(false)
  }
  const submitProfile = (e) => {
    e.preventDefault();
    axios.post('/user?email=' + user.email, {
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      bio: profile.bio,
      street1: address.street1,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country
    })
    .then(res => {
      setProfile(prevState => ({
        ...prevState,
        name: res.data.name,
        email: res.data.email,
        phone: res.data.phone,
        bio: res.data.bio,
      }));
      if(res.data.address) {
        setAddress(prevState => ({
          ...prevState,
          street1: res.data.address.street1,
          city: res.data.address.city,
          state: res.data.address.state,
          zip: res.data.address.zip,
          country: res.data.address.country,
        }));
      }
    })
    .catch(err => console.log(err));
  };

  // const orderHeader = (
  //   <>
  //     <thead>
  //       <tr>
  //         <th scope="col" data-sortable='true'> Date </th>
  //         <th scope="col" data-sortable='true'> Order ID </th>
  //         <th className="section-hide" scope="col" data-sortable='true'> Membership </th>
  //         <th className="col-width-200px" scope="col" data-sortable='true'> Amount </th>
  //       </tr>
  //     </thead>
  //   </>
  // )

  // const ordersDisplay = (
  //   <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders">
  //     <table className='table row-number'>
  //       {orderHeader}
  //       <tbody >
  //       {orders.map((order, i) => (
  //         <tr key={order.orderId}>
  //           <td className="section-hide"> {moment(order.createdAt).format("DD-MMM-YY")}</td>
  //           <td className="section-hide"> {order.orderId}</td>
  //           <td className="section-hide"> {order.membership} </td>
  //           <td> {order.amount} </td>
  //         </tr>
  //       ))}
  //     </tbody>
  //   </table>
  // </div>
  // )
  const membershipHistoryHeader = (
    <>
      <thead>
        <tr>
          <th scope="col" data-sortable='true'> Membership Type </th>
          <th scope="col" data-sortable='true'> Start Date </th>
          <th scope="col" data-sortable='true'> End Date </th>
        </tr>
      </thead>
    </>
  )

  const orderCards = (
    <div className="full-width">
      <div>
        <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders text-center">
          <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ORDER HISTORY</h1>

          <table className='table row-number'>
            <thead>
              <tr>
                <th scope="col" data-sortable='true'> Membership Type </th>
                <th scope="col" data-sortable='true'> Amount </th>
                <th scope="col" data-sortable='true'> Payment Status </th>
                <th scope="col" data-sortable='true'> Order Date </th>
                <th scope="col" data-sortable='true'> Order ID </th>
                <th scope="col" data-sortable='true'> Receipt </th>

              </tr>
            </thead>
            <tbody >
              {orders.map((order, i) => (
                <tr key={i}>
                  <td className="section-hide"> {order.membership}</td>
                  <td className="section-hide"> Rs.{order.amount}</td>
                  <td className="section-hide"> <strong> PAID </strong></td>
                  <td className="section-hide"> {order.orderDate ? moment(order.orderDate).format("DD-MMM-YY") : moment(order.createdAt).format("DD-MMM-YY")} </td>
                  <td className="section-hide"> {order.orderId} </td>
                  <td className="section-hide"> <input className = "btn btn-large btn-primary" type='submit' value='VIEW RECEIPT' name="Submit" onClick={() => viewReceipt(i)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  
  const receiptSection = (
    <>
     <div>
        <p className="inv-no"><strong>Inv No: {receipt.id}</strong></p>      
        <p className="inv-no"><strong>Inv Date: {moment(receipt.orderDate).format("DD-MMM-YY")}</strong></p>      
      <div id="mid">
        <div className="contact-info">
          <h4>Contact Info</h4>
          <p> 
              Name    : {receipt.memberName}<br/>
              Email   : {receipt.email}<br/>
              Phone   : {receipt.phone}<br/>
          </p>
        </div>
      </div>
    
      <div id="bot">

        <div>
          <table className="table">
            <tr class="tabletitle">
              <td class="item"><h4>Item</h4></td>
              <td class="Hours"><h4>Qty</h4></td>
              <td class="Rate"><h4>Sub Total</h4></td>
            </tr>

            <tr class="service">
              <td class="tableitem"><p class="itemtext">{receipt.membership}</p></td>
              <td class="tableitem"><p class="itemtext">1</p></td>
              <td class="tableitem"><p class="itemtext">Rs. {receipt.amount}</p></td>
            </tr>

            <tr class="tabletitle">
              <td></td>
              <td class="Rate"><h4>Total Amount</h4></td>
              <td class="payment"><h4>Rs. {receipt.amount}</h4></td>
            </tr>

          </table>
        </div>
				</div>
      </div>
    </>
  )

  const membershipHistorySection = (
    <div>
      <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12 b2corders text-center">
        <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> MEMBERSHIP HISTORY</h1>

        <table className='table row-number'>
          {membershipHistoryHeader}
          <tbody >
            {membershipHistory.map((membershipHis, i) => (
              <tr key={i}>
                <td className="section-hide"> {membershipHis.membershipType}</td>
                <td className="section-hide"> {moment(membershipHis.startDate).format("DD-MMM-YY")}</td>
                <td className="section-hide"> {moment(membershipHis.endDate).format("DD-MMM-YY")} </td>
              </tr>
            ))}
          </tbody>
          </table>
      </div>
    </div>
  );

  return (
    <main>
      <Modal show={show} onHide={handleClose} centered={1}>
        <Modal.Header>
          <Modal.Title>
            <div>
              <img className = "thedojologo" src="https://cdn.razorpay.com/logos/HhAmdevLDzPKGa_medium.jpg"/> 
              <h2 className="receipt-header"> THE DOJO </h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {receiptSection}
        <hr className="hr-large"/>
        <p class="legal">The Dojo <br/> #403, 3rd Floor, 22nd Cross, 2nd Sector, HSR Layout, Bangalore, 560102
        <br/> <strong>Thank you for your business!</strong>  			</p>			

       </Modal.Body>
      </Modal>

      <div className="contact-form">
      <div className="fixing-newsletter">
        <div className="thumbnail center well well-sm text-center">
          <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> PROFILE</h1>
          <hr className="hr-large d-none d-md-block"/>
          <div className="rowcontainer">
            <div className="col-md-12 col-xl-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-md-4">
                 <div className="md-form">
                   <input  className="form-control fixed-input" name="name" value={moment(profile.startDate).format("DD-MMM-YY")} disabled/>
                     <label htmlFor="name">Membership Start Date</label>
                  </div>
                </div>
                <div className="col-md-4">
                 <div className="md-form">
                   <input  className="form-control fixed-input" name="email" value={profile.email} disabled/>
                   <label htmlFor="email">Your Email</label>
                 </div>
               </div>
               <div className="col-md-4">
                <div className="md-form">
                  <input  className="form-control fixed-input" name="name" value={moment(profile.endDate).format("DD-MMM-YY")} disabled/>
                  <label htmlFor="name">Membership End Date</label>
                 </div>
               </div>
              </div>
            </div>
           <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12">
             <div className="row">
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="name" value={profile.name} onChange={handleSetProfile}/>
                    <label htmlFor="name">Your Name</label>
                 </div>
               </div>
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="phone" value={profile.phone} onChange={handleSetProfile}/>
                  <label htmlFor="phone">Your Phone Number</label>
                </div>
               </div>
             </div>
             <div className="row">
              <div className="col-md-12">
                <div className="md-form">
                  <textarea  className="form-control" name="bio" value={profile.bio} onChange={handleSetProfile}/>
                  <label htmlFor="message">Bio</label>
                </div>
              </div>
            </div>
           </div>
           <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12">
             <div className="row">
               <div className="col-md-12">
                <div className="md-form">
                  <input  className="form-control" name="street1" value={address.street1} onChange={handleSetAddress}/>
                    <label htmlFor="name">Street</label>
                 </div>
               </div>
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="city" value={address.city} onChange={handleSetAddress}/>
                  <label htmlFor="email">City</label>
                </div>
               </div>
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="state" value={address.state} onChange={handleSetAddress}/>
                  <label htmlFor="phone">State</label>
                </div>
               </div>
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="zip" value={address.zip} onChange={handleSetAddress}/>
                  <label htmlFor="phone">Zip Code</label>
                </div>
               </div>
               <div className="col-md-6">
                <div className="md-form">
                  <input  className="form-control" name="country" value={address.country} onChange={handleSetAddress}/>
                  <label htmlFor="phone">Country</label>
                </div>
               </div>
             </div>
           </div>
         </div>
         <input className = "btn btn-large btn-primary" type='submit' value='UPDATE PROFILE' name="Submit" onClick={submitProfile} />
       </div>
       <hr className="hr-large"/>
       {membershipHistorySection}
       <div className="thumbnail center well well-sm text-center">
         <h1 className="space col-md-12 col-xl-12 col-sm-12 col-xs-12"> ORDERS </h1>
         <hr className="hr-large d-none d-md-block"/>
         <div className="rowcontainer">
          {orderCards}
         </div>
       </div>

      <hr className="hr-large"/>
     </div>
   </div>
</main>
  );
}

export default Profile
